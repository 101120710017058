.fidcl-text-center {
  text-align: center;
}

.fidcl-h1,
.fidcl-h1 div,
.fidcl-h1 div div,
.fidcl-h1 div div div,
.fidcl-h1 div div div div {
  color: rgb(126, 43, 137);
  font-size: 2.4rem !important;
  font-weight: 400;
  margin: 0;
}

.fidcl-h2 {
  font-size: 3.8rem;
}

.fidcl-h3 {
  font-size: 3rem;
}

.fidcl-h4 {
  font-size: 2.4rem;
}

.fidcl-h5 {
  font-size: 2rem;
}

.fidcl-h6 {
  font-size: 1.6rem;
}

.fidcl-h1-sub {
  font-size: 1.6rem;
  margin: 0;
  font-weight: 400;
}

.fidcl-subtitle {
  margin: 1rem 0;
}

.fidcl-section-title-home {
  color: #522e76;
  text-align: center;
  margin: 3rem 0 1.5rem 0;
  font-size: 3rem;
  font-weight: 200;
}

.fidcl-small {
  font-size: 1.2rem;
}

.fidcl-copyright-footer {
  color: white;
  font-size: 1.2rem;
  margin: 1.5rem 0;
  padding: 0;
}

.fidcl-p {
  color: #666666;
  font-size: 18px;
  font-weight: 400;
}

.fidcl-container {
  width: 90%;
  max-width: 120rem;
  margin: 3rem auto 0 auto;
}

.fidcl-container-home {
  padding: 0 1.5rem;
  width: 123rem;
  margin: 0 auto;
  max-width: 100%;
}

.fidcl-container-home > * {
  padding: 0 !important;
  margin-top: 0 !important;
}

@media (max-width: 1199px) {
  .fidcl-container-home {
    padding: 0 !important;
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .fidcl-p {
    font-size: 16px;
  }
}

.fidcl-container-transac {
  width: 90% !important;
  min-width: auto !important;
  margin-bottom: 15px;
}

.fidcl-divider {
  height: 1.5rem;
  width: 100%;
  clear: both;
}

.formview-container {
  width: 90%;
  max-width: 90rem;
  margin: 0 auto;
}

.fidcl-card-header {
  background: #ffffff;
  border: none;
}

.fidcl-form-data,
.fidcl-display-data {
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
}

.fidcl-display-fullname {
  text-transform: capitalize;
}

.fidcl-select {
  display: block;
  width: 100%;
  border: #87318b solid 0.1rem;
  padding: 0.6rem 1.2rem !important;
  height: 3.6rem;
  margin: 0;
  position: relative;
}

.form-control {
  display: block;
  width: 100%;
  border: #87318b solid 0.1rem;
  padding: 0.6rem 1.2rem !important;
  height: 3.6rem;
  margin: 0;
  position: relative;
}

.form-control * {
  margin: 0;
  padding: 0;
  border: none;
}

/* fix para componentes input base de react native*/

.fidcl-data *[class*='InputBase__TotalContainer'] div:nth-child(2) {
  height: 3.6rem;
  line-height: 1.8;
  font-size: 1.4rem;
}

.fidcl-data *[class*='InputBase__TotalContainer'] div:nth-child(2) div {
  margin: 0;
  padding: 0;
  width: auto;
  height: 100% !important;
  border-color: rgb(206, 212, 218) !important;
}

.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control {
  display: block;
  width: 100%;
  border: none !important;
  padding: 0.6rem 1.2rem !important;
  height: 100% !important;
  margin: 0;
  position: relative;
  border-radius: 0.5rem;
  font-size: 1.4rem !important;
  line-height: 1.4 !important;
  padding-top: 0.8rem !important;
}

.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control:hover,
.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control:active,
.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: inherit !important;
  line-height: 2 !important;
  transform: none !important;
  height: 100% !important;
  background: #66339900;
}

.fidcl-formaction button {
  vertical-align: top;
}

.fidcl-formaction button[type='submit'] {
  margin-left: 1.5rem;
}

.fidcl-data-select {
  position: relative;
}

.fidcl-data-select::before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #5b2b75;
  position: absolute;
  bottom: 16px;
  right: 10px;
}

.fidcl-bredcrumbs-arrow {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 5px;
  background: #ffffff00;
  line-height: 1 !important;
  color: #ff000000 !important;
}

.fidcl-bredcrumbs-arrow::after {
  position: absolute;
  display: inline-block;
  content: '';
  border-right: 0.1rem solid rgb(176, 176, 176);
  border-bottom: 0.1rem solid rgb(176, 176, 176);
  width: 6px;
  height: 6px;
  left: 0.4rem;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.fidcl-close {
  display: none !important;
}

.fidcl-healding-main {
  color: hsla(270, 44%, 32%, 1);
  margin: 3rem 0;
  font-size: 3rem;
}

/* Colors */

.fidcl-bg-success-primary {
  background-color: hsl(149, 86%, 36%);
}

.fidcl-bg-success-secondary {
  background-color: #d9f2e5ff;
}

.fidcl-bg-warn-primary {
  background-color: hsl(49, 100%, 39%);
}

.fidcl-bg-warn-secondary {
  background-color: #fff5cc;
}

.fidcl-bg-danger-primary {
  background-color: hsl(357, 91%, 55%);
}

.fidcl-bg-danger-secondary {
  background-color: #fddee0ff;
}

.fidcl-bg-info-primary {
  background-color: #6e2f86ff;
}

.fidcl-bg-info-secondary {
  background-color: #e9e0ed;
}

.fidcl-clr-primary {
  color: #522e76;
}

.fidcl-clr-success-primary {
  color: hsl(149, 86%, 36%);
}

.fidcl-clr-success-secondary {
  color: #d9f2e5ff;
}

.fidcl-clr-warn-primary {
  color: hsl(49, 100%, 39%);
}

.fidcl-clr-warn-secondary {
  color: #fff5cc;
}

.fidcl-clr-danger-primary {
  color: hsl(357, 91%, 55%);
}

.fidcl-clr-danger-secondary {
  color: #fddee0ff;
}

.fidcl-clr-info-primary {
  color: #6e2f86ff;
}

.fidcl-clr-info-secondary {
  color: #e9e0ed;
}

.fidcl-border-success-primary {
  border-color: hsl(149, 86%, 36%);
}

.border-success-secondary {
  border-color: #d9f2e5ff;
}

.fidcl-border-warn-primary {
  border-color: hsl(49, 100%, 39%);
}

.fidcl-border-warn-secondary {
  border-color: #fff5cc;
}

.fidcl-border-danger-primary {
  border-color: hsl(357, 91%, 55%);
}

.fidcl-border-danger-secondary {
  border-color: #fddee0ff;
}

.fidcl-border-info-primary {
  border-color: #6e2f86ff;
}

.fidcl-border-info-secondary {
  border-color: #e9e0ed;
}

/* Pagination */

.fidcl-pagination {
  float: right;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.fidcl-pagination *[data-testid*='Pagination__Button'],
.fidcl-pagination .page-link {
  float: left;
  font-size: 1.6rem;
  position: relative;
  display: block;
  padding: 0.6rem 1rem 0.4rem 1rem;
  line-height: 2rem;
  color: hsla(0, 0%, 80%, 0.95);
  background-color: #fff;
  border: 1px solid hsl(0, 0%, 81%);
  border-right: none;
  text-decoration: none;
  font-weight: 200;
}

.fidcl-pagination *[data-testid*='Pagination__Button']:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

.fidcl-pagination *[data-testid*='Pagination__Button']:last-child {
  border: 1px solid hsla(0, 0%, 80%, 0.95);
  border-radius: 0 0.5rem 0.5rem 0;
}

.fidcl-pagination .page-link svg {
  height: 1.7rem;
  width: auto;
}

.fidcl-pagination .page-link svg path {
  fill: hsla(0, 0%, 80%, 0.95);
}

.fidcl-pagination *[data-testid*='Pagination__Button']:disabled,
.fidcl-pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.fidcl-pagination .page-link.fidcl-pagination-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: 0.48rem 0.5rem 0.5rem 0.6rem;
  height: 3.2rem;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button']:not(:disabled):not(.disabled):not(.active):hover,
.page-item:not(:disabled):not(.disabled):not(.active):hover * {
  background-color: #f9f9f9;
  color: #522e76;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button--siguente']:not(:disabled):not(.disabled):not(.active)
  div
  div,
.fidcl-pagination
  *[data-testid*='Pagination__Button--siguente']:not(:disabled):not(.disabled):not(.active):hover
  div
  div {
  color: #522e76 !important;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button--anterior']:not(:disabled):not(.disabled):not(.active)
  div
  div,
.fidcl-pagination
  *[data-testid*='Pagination__Button--anterior']:not(:disabled):not(.disabled):not(.active):hover
  div
  div {
  color: #522e76 !important;
}

.fidcl-pagination *[data-testid*='Pagination__Button--siguente']:disabled,
.fidcl-pagination *[data-testid*='Pagination__Button--anterior']:disabled {
  background: #ffffff;
  border-color: #cfcfcf;
  cursor: not-allowed;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button--siguente']:disabled
  div
  div,
.fidcl-pagination
  *[data-testid*='Pagination__Button--anterior']:disabled
  div
  div {
  color: #cfcfcf !important;
}

.page-item:not(:disabled):not(.disabled):not(.active):hover path {
  fill: #522e76;
}

.page-item.active {
  border-right: solid #522e76;
  z-index: 3;
}

.fidcl-header-und {
  font-size: 2.4rem;
  color: #522e76;
  margin-bottom: 1.5rem;
}

.fidcl-header-und img {
  width: 4.5rem;
  margin-top: -0.5rem;
}

.product-container {
  background: #e4e4e4;
}

.col-2_4 {
  width: 20%;
  padding: 0 1.5rem;
}

.fidcl-container-ax-like {
  padding: 0 1.5rem !important;
  width: 123rem !important;
  margin: 0 auto !important;
  max-width: 100% !important;
  margin-bottom: 25px !important;
}

/* RESPONSIVE */

@media (min-width: 768px) {
  .success-container {
    margin-top: 5rem;
  }
  .hide-desktop {
    display: none !important;
  }
}

.fidcl-close-css {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 3rem;
  height: 3rem;
}

.fidcl-close-css:hover {
  opacity: 1;
}

.fidcl-close-css:before,
.fidcl-close-css:after {
  position: absolute;
  left: 15px;
  content: '';
  height: 3rem;
  width: 2px;
  background-color: #522e76;
}

.fidcl-close-css:before {
  transform: rotate(45deg);
}

.fidcl-close-css:after {
  transform: rotate(-45deg);
}

.fidcl-card {
  margin: 1.5rem 0;
  background: white;
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-transition: ease-in box-shadow 0.1s;
  transition: ease-in box-shadow 0.1s;
  border-radius: 0.3rem;
}

.fidcl-card h6 {
  text-align: center;
  color: #522e76;
  margin: 1.5rem 0;
}

.fidcl-card:hover {
  box-shadow: 0 0 1rem 0rem hsla(0, 0%, 64%, 0.14);
  -webkit-transition: ease-in box-shadow 0.1s;
  transition: ease-in box-shadow 0.1s;
}

.fidcl-card.fidcl-card-product:hover button {
  background-color: #522e76;
  color: white;
  -webkit-transition: ease-in background-color 0.1s;
  transition: ease-in background-color 0.1s;
}

.fidcl-card-product {
  min-height: 27rem;
  position: relative;
}

.fidcl-card-product .fidcl-button {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
}

.fidcl-table-responsive {
  width: 100%;
  overflow: auto;
}

.fidcl-table {
  font-size: 1.4rem;
  width: 100%;
  margin: 3rem 0;
}

.fidcl-table thead {
  background: #e9e0edff;
  color: #522e76;
}

.fidcl-table tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.fidcl-table th,
td {
  padding: 0.75rem 1.5rem;
  border: solid 1px #a0a6ab;
}

.fidcl-table th {
  text-align: left;
}

.fidcl-table td {
  text-align: left;
}

.fidcl-th-action {
  text-align: center;
}

.fidcl-th-number {
  text-align: right;
}

.fidcl-td-action {
  text-align: center;
  padding: 0 !important;
}

.fidcl-td-number {
  text-align: right;
}

/* Table Card */

.fidcl-table-card {
  margin: 0;
}

.fidcl-table-card th,
.fidcl-table-card td {
  border: none;
  border-bottom: solid 0.1rem #e9e0ed;
  padding: 1.5rem;
}

.fidcl-table-card th {
  background: white;
  font-weight: 800;
  color: hsla(0, 0%, 40%, 1);
}

.fidcl-table-card tbody tr:hover {
  background: rgb(249, 249, 249);
}

.fidcl-table-card tr:last-child td {
  border-bottom: none;
}

/* Table Data */

.fidcl-table-data {
  margin: 0;
}

.fidcl-table-data th,
.fidcl-table-data td {
  border: none;
  border-bottom: solid 0.1rem #e9e0ed;
  padding: 1rem !important;
}

.fidcl-table-data th {
  background: white;
  font-weight: 800;
  color: hsla(0, 0%, 40%, 1);
}

.fidcl-table-data tbody tr:hover {
  background: rgb(249, 249, 249);
}

.fidcl-table-data tr:last-child td {
  border-bottom: none;
}

.voucher-container {
  width: 900px;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.fidcl-button {
  padding: 4px;
}

.center-content-row {
  text-align: center;
}

.show-desktop {
  display: block;
}

.show-mobile {
  display: none;
}

@media (max-width: 767px) {
  .success-container {
    margin-top: 10rem;
  }
  .col-2_4 {
    width: 100%;
  }
  .mx-mobile-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .pt-mobile-4 {
    padding-top: 1.5rem;
  }
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .product-container {
    background: #ffffff;
    margin-left: 0;
    margin-right: 0;
  }
  .hide-mobile {
    display: none !important;
  }
  .fidcl-header-und {
    font-size: 2rem;
    color: #522e76;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .fidcl-header-und img {
    width: 4rem;
    margin-top: -0.5rem;
  }
  .fidcl-card-product-horizontal-mobile {
    padding-left: 50%;
    min-height: 20.5rem !important;
  }
  .fidcl-card-product-horizontal-mobile > .img-container {
    position: absolute;
    left: 1.5rem;
    width: 50%;
  }
  .fidcl-card-product-horizontal-mobile h6 {
    padding-left: 3rem;
    text-align: left;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .success-container {
    margin-top: 5rem;
  }
  .hide-desktop {
    display: none !important;
  }
  .hide-mobile {
    display: block !important;
  }
  .fidcl-copyright-footer {
    margin-bottom: 0rem;
  }
  *[class*='RedesSociales'] a > div {
    border: none;
  }
  *[class*='footerContainer'] > div:last-child > div {
    border-bottom: none !important;
  }
}

.fidcl-table-data td.fidcl-td-action-center {
  text-align: center;
}

div.fidcl-button-secondary-outline > div:first-child {
  color: #522e76;
  border: #522e76 solid 0.1rem;
  background: #fff !important;
}

div.fidcl-button-secondary-outline > div:first-child:hover {
  color: #ffffff;
  -webkit-transition: ease-in all 0.1s;
  transition: ease-in all 0.1s;
  background-color: hsl(270, 44%, 32%);
}

div.fidcl-button-secondary-outline > div:first-child > div:first-child {
  color: #522e76 !important;
}

/* FIX DATE SELECT */

.fidcl-col-date-fix {
  margin-bottom: 1.5rem;
}

.fidcl-date-select-container {
  max-width: 35rem;
}

.fidcl-date-select-container > * {
  display: inline-block;
  font-size: 0 !important;
  margin: 0 !important;
}

.fidcl-date-select-container > * > * {
  font-size: 1.4rem;
}

.fidcl-date-select-container > div {
  border-radius: 0;
  width: 48%;
  border: 0;
  height: 4.2rem;
  position: relative;
}

.fidcl-date-select-container > div::before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.6rem solid #5b2b75;
  position: absolute;
  bottom: 1.7rem;
  right: 1rem;
}

.fidcl-date-select-container > div > select {
  height: 4.2rem;
  background: #66339900;
}

.fidcl-date-select-container > div:first-child {
  border-radius: 0;
  width: 25%;
}

.fidcl-date-select-container > div:last-child {
  border-radius: 0;
  width: 27%;
}

.fidcl-date-select-container > div:nth-child(2) select {
  border-right: 0;
  border-radius: 0;
  border-left-width: 0.1rem;
}

.fidcl-date-select-container > div select {
  border-radius: 0;
  border-color: rgb(102, 102, 102);
  border-style: solid;
  border-width: 0.1rem;
}

.fidcl-date-select-container > div:first-child select {
  border-width: 0.1rem;
  border-right: 0;
  border-radius: 0.5rem 0 0 0.5rem;
}

.fidcl-date-select-container > div:last-child select {
  border-width: 0.1rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

/* fidcl-phone-fix-container */

.fidcl-phone-fix-container {
  position: relative;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div {
  padding: 0 !important;
  position: relative;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div:nth-child(1)::before {
  content: '+569';
  position: absolute;
  font-size: 1.4rem;
  color: #666666;
  padding-left: 1.5rem;
  padding-top: 1rem;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div input {
  padding: 0.5rem 1.5rem 0.5rem 5.5rem;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div input:focus {
  outline: none;
}

.fidcl-btn-back {
  display: inline-block !important;
  width: 10rem !important;
  text-transform: capitalize;
}

.btn-table-outline {
  color: #522e76;
  background: #ffffff;
  border-color: #ffffff;
  height: 3.3rem;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  width: auto;
  min-height: auto;
}

.btn-table-icon-left {
  line-height: 2.3;
  height: 3.3rem !important;
  padding-left: 3rem !important;
  position: relative;
  box-sizing: border-box !important;
}

.btn-table-icon-left svg {
  left: 0.5rem;
  position: absolute;
  top: 0.5rem;
}

tr:hover .btn-table-outline {
  color: #522e76;
  border-color: hsla(270, 44%, 32%, 1);
  background-color: rgb(249, 249, 249);
}

tr .btn-table-outline:hover {
  background-color: #522e76;
  border-color: hsla(270, 44%, 32%, 1);
  color: rgb(249, 249, 249);
}

.btn-pure-icon {
  max-width: 4.3rem;
  line-height: 2.5;
  text-align: center;
  padding: 0 !important;
}

.fidcl-btn {
  display: inline-block !important;
  width: auto !important;
  height: 4rem !important;
  line-height: 4rem;
}

.fidcl-home__icon-banner {
  text-align: center;
  display: block;
  width: 100%;
}

.fidcl-home__icon-banner-container > div {
  text-align: center;
}

.fidcl-home__icon-banner-container .anchor-without-href:hover {
  cursor: pointer;
  text-decoration: underline !important;
  text-decoration-color: #522e76 !important;
}

.fidcl-home__icon-banner-container > div > a > div,
.fidcl-home__icon-banner-container > div > div {
  max-width: 10rem;
  display: inline-block;
  border: solid 0.3rem rgb(82, 46, 117);
  border-radius: 50%;
  padding: 2rem;
}

.fidcl-home__icon-banner-container > a h3,
.fidcl-home__icon-banner-container > div h3 {
  font-size: 1.6rem;
  color: rgb(82, 46, 117);
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  font-weight: 200 !important;
}

.container-icon-loading {
  display: flex;
}

.container-icon-loading .container-icon-loading-label {
  margin-right: 1rem;
}

/* fidcl-transactions-side */

@media (max-width: 767px) {
  .fidcl-transactions-side {
    width: 100%;
  }
}

.fidcl-transactions-side__box {
  flex-direction: row;
  align-self: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
}

.fidcl-transactions-side__box-sec-a,
.fidcl-transactions-side__box-sec-b {
  background: #a13e92;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.fidcl-transactions-side__box-sec-a {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.fidcl-transactions-side__box-sec-b {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.fidcl-transactions-side__box-sec-a .fidcl-h2 {
  font-size: 2rem;
  color: #ffffff;
  text-transform: capitalize;
}

.fidcl-transactions-side__box-sec-a .fidcl-h3 {
  font-size: 1.8rem;
  color: #ffffff;
  text-transform: capitalize;
}

.fidcl-footer > div:nth-child(1) > div:nth-child(2) {
  z-index: 1 !important;
}

.fidcl-card-mobile-title {
  line-height: 2rem;
  font-size: 1.6rem;
}

/* PRODUCT */

.fidcl-product-card {
  max-width: 100%;
}

.fidcl-product-card:hover {
  color: #522e76;
  text-decoration: none;
  background-color: transparent;
}

.fidcl-product-card .btn {
  font-weight: 600;
}

.fidcl-product-card h4 {
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.3;
  font-size: 1.5rem;
  width: 100%;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.fidcl-product-card_badges-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.fidcl-product-card__image-container {
  position: relative;
  text-align: center;
}

.fidcl-product-card__image-container > img {
  max-width: 100%;
  height: 200px !important;
  min-height: 200px !important;
  max-height: 100%;
}

.fidcl-product-card__img-agotado {
  filter: grayscale(100%);
  opacity: 0.5;
}

.fidcl-product-card__tag-agotado {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  padding: 7px 15px;
  position: absolute;
  top: 54px;
  text-align: center;
  width: 100%;
}

.fidcl-product-card__tag-custom-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.fidcl-product-card__body-container {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.fidcl-product-card__puntos-container {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1;
  margin-top: 0.5rem;
}

.fidcl-product-card__puntos-container.strike-through {
  text-decoration: line-through #e72470 3px !important;
}

.fidcl-product-card__puntos-container span {
  font-size: 1.4rem;
  font-weight: 200;
  color: hsla(0, 0%, 10%, 1);
}

.fidcl-product-card .btn {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.fidcl-product-card:hover .fidcl-product-card__body-container {
  color: #522e76;
}

.fidcl-product-card:hover .btn-outline-primary {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.fidcl-product-card__body-container {
  text-align: center;
}

/* card por vista */

.card:hover {
  box-shadow: 0px 0px 6px #ababab82;
}

.slick-slide .card {
  height: 35rem;
  width: 26rem;
  margin: 1.5rem auto;
}

@media (min-width: 0px) {
  div:not(.slick-slide) > div > a > .card {
    height: 38rem;
    width: 26rem;
    margin: 1.5rem;
  }
}

.card > * {
  margin: 1.2rem;
  position: relative;
  height: 100%;
}

/*@media (max-width: 1039px) {*/
/*  *[class*='Catalogo__Title'] > h1,*/
/*  .Catalogo__Title > h1 {*/
/*    font-size: 2.4rem;*/
/*    margin: 1.2rem 0 1.5rem 0;*/
/*    padding: 0;*/
/*  }*/
/*  *[class*='ButtonFilter__Filter'] {*/
/*    right: 0 !important;*/
/*  }*/
/*  div:not(.slick-slide) > div > a > .card {*/
/*    height: 22rem;*/
/*    max-width: 90%;*/
/*    width: 32rem;*/
/*    margin: 1.5rem auto;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card_badges-container {*/
/*    min-height: auto;*/
/*    height: 100%;*/
/*    pointer-events: none;*/
/*    width: 100%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card_badges-container*/
/*    *[class*='EditContainer'] {*/
/*    position: absolute;*/
/*    bottom: 0px;*/
/*    width: 3.7rem;*/
/*    height: 3.6rem;*/
/*  }*/
/*  div:not(.slick-slide) > div > .fidcl-product-card .btn {*/
/*    position: absolute;*/
/*    left: auto;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*    width: 45%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card__body-container {*/
/*    text-align: left;*/
/*    position: absolute;*/
/*    bottom: auto;*/
/*    left: auto;*/
/*    right: 0;*/
/*    width: 45%;*/
/*    top: 0;*/
/*    height: 75%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card__image-container {*/
/*    width: 50%;*/
/*  }*/
/*  .fidcl-product-card__image-container > img {*/
/*    width: 100%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card__puntos-container {*/
/*    font-size: 2.2rem;*/
/*    font-weight: 600;*/
/*    line-height: 1;*/
/*    margin-top: 0.5rem;*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*  }*/
/*  .fidcl-catalogo-tienda-und *[class*='ButtonFilter__Filter'] {*/
/*    right: 1.5rem !important;*/
/*  }*/
/*}*/

/* ACCOUNT CARD */

.fidcl-card-account-container-row > div {
  margin-bottom: 3rem;
}

.fidcl-card-account-container-row > div:last-child {
  padding-right: 0;
}

.fidcl-card-account-container-row > div:first-child {
  padding-left: 0;
}

@media (max-width: 767px) {
  .fidcl-card-account-container-row > div {
    padding: 0;
  }
}

/*
.fidcl-card-account-container-row > div:first-child {
  padding-left: 0;
}

.fidcl-card-account-container-row > div:last-child {
  padding-right: 0;
}
*/

.fidcl-card-account-container > * {
  margin: 0;
  background: white;
  padding: 0;
  box-shadow: 0 0 0.75rem 0rem hsla(0, 0%, 0%, 0);
  -webkit-transition: ease-in box-shadow 0.1s;
  transition: ease-in box-shadow 0.1s;
  border-radius: 0.3rem;
  text-align: center;
  overflow: hidden;
  border: solid 1px #e6e6e6;
  height: 100%;
}

.fidcl-card-account-container-row {
  width: 100%;
  margin: 0;
}

.fidcl-card-account-container > * > * {
  padding: 1.5rem;
}

.fidcl-card-account-container > * > *:first-child {
  background: hsl(302, 52%, 36%);
  margin: 0;
  color: #ffffff;
  font-size: 1.5rem;
}

.fidcl-card-account-container > * > *:last-child {
  padding: 1.5rem !important;
  margin: 0 !important;
}

.fidcl-card-account-container > * > *:last-child > div {
  font-size: 2.4rem;
  margin: 0;
}

.fidcl-card-account-container .fidcl-card-account-full a {
  font-size: 1.4rem;
  text-decoration: underline !important;
}

.fidcl-card-puntos-full {
  height: 100%;
}

.fidcl-card-puntos-empty {
  background: #e9e0ed;
}

.fidcl-card-account-empty h5 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #5b2b75;
}

.fidcl-card-account-empty a {
  font-size: 1.4rem;
  text-decoration: underline !important;
}

.fidcl-account-subtitle .fidcl-h2 {
  font-weight: 200;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.fidcl-card-link-header {
  position: relative;
}

.fidcl-card-link-header__link {
  font-size: 1.5rem !important;
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;
}

.fidcl-card-account-container > * > .fidcl-card-link-header {
  padding-right: 10.5rem !important;
}

.fidcl-card-link-header__link a {
  color: #522e76;
  border: solid 0.1rem #522e76;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  transition: border-color 0.1s ease-in;
  width: 100%;
}

.fidcl-card-link-header__link a:hover {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

/* ESTILOS GLOBALES PARA MARKDOWN .fidcl-markdown {} */

.fidcl-markdown a {
  color: #522e76;
  text-decoration: underline;
}

.fidcl-markdown a:hover {
  color: #522e76;
  text-decoration: none;
}

.fidcl-contact-message {
  margin: 1.5rem 0;
  background: #e5e5e5;
  font-size: 1.5rem;
  padding: 1.5rem;
  text-align: center;
  position: relative;
}

.fidcl-contact-message:before {
  content: '';
  background: #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2000px;
  width: 2000px;
}

.fidcl-contact-message:after {
  content: '';
  background: #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2000px;
  width: 2000px;
}

.fidcl-checkbox-container > div {
  max-width: 100% !important;
}

.fidcl-radio-container {
  font-size: 1.5rem;
  padding: 0 0 1.5rem 0;
}

.fidcl-radio-container [type='radio']:checked,
.fidcl-radio-container [type='radio']:not(:checked) {
  opacity: 0;
  display: none;
}

.fidcl-radio-container [type='radio']:checked + label,
.fidcl-radio-container [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.fidcl-radio-container [type='radio']:checked + label:before,
.fidcl-radio-container [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.fidcl-radio-container [type='radio']:checked + label:after,
.fidcl-radio-container [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #5b2b75;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
}

.fidcl-radio-container:hover [type='radio'] + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #5b2b75;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
}

.fidcl-radio-container [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.fidcl-radio-container:hover [type='radio'] + label:after {
  opacity: 0.5;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.fidcl-radio-container [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.fidcl-with-loading .loader-container {
  position: relative;
  min-height: 4rem;
}

.fidcl-with-loading .loader-container .spinner {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
