.custom-tab-panel {
  width: 100%;
  overflow: hidden;
}

.custom-tab-header {
  display: flex;
}

.custom-tab-header-item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  user-select: none;
  color: #6f6f6f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  font-family: 'OpenSans-Medium';
}

.custom-tab-header-item.active {
  background-color: #ffffff;
  color: #783184;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-bottom: 2px solid #783184;
  font-family: 'OpenSans-SemiBold';
}

.custom-tab-content {
  padding: 20px 0 0 0;
  background-color: #fff;
}

@media (max-width: 768px) {
  .custom-tab-header {
    flex-direction: row;
  }
  .custom-tab-content {
    margin-top: 24px;
    padding: 0;
  }
  .custom-tab-panel {
    padding: 24px 0 0 0;
  }
}
