.category-list-title {
  display: flex;
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-family: OpenSans-SemiBold;
  line-height: 27px; /* 150% */
  -webkit-box-pack: justify; /* Antiguos navegadores WebKit */
  -ms-flex-pack: justify; /* Antiguos IE */
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eaebec;
}
.category-list-title-mobile {
  display: none;
  -webkit-box-pack: justify; /* Antiguos navegadores WebKit */
  -ms-flex-pack: justify; /* Antiguos IE */
  justify-content: space-between;
  margin-bottom: 30px;
}

.no-menu-overlay {
  -webkit-box-flex: 3; /* Antiguos navegadores WebKit */
  -ms-flex: 3;
  flex: 3;
}

.main-menu-content {
  position: relative;
  top: 0;
  left: 0;
  padding: 36px;
  width: 400px;
  background: #f3f2f7;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  overflow-x: hidden;
  transform: translateX(-400px);
  transition: transform 0.5s;
  z-index: 3;
}

.main-menu-content.opened {
  transform: translateX(0px);
  width: 400px;
  padding: 36px;
  height: calc(100vh - 80px);
}

.main-menu-content > ul {
  display: -webkit-box; /* Antiguos navegadores WebKit */
  display: -ms-flexbox; /* Antiguos IE */
  display: flex;
  -webkit-box-orient: vertical; /* Antiguos navegadores WebKit */
  -webkit-box-direction: normal; /* Antiguos navegadores WebKit */
  -ms-flex-direction: column; /* Antiguos IE */
  flex-direction: column;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.main-menu-content > ul > li {
  width: 319px;
  height: 46px;
  color: #212529;
  font-size: 18px;
  font-family: OpenSans-Medium;
  font-style: normal;
  line-height: 27px;
  list-style-type: none;
  display: flex;
  -webkit-box-pack: justify; /* Antiguos navegadores WebKit */
  -ms-flex-pack: justify; /* Antiguos IE */
  justify-content: space-between;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.main-menu-content > ul > li:hover {
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #e4d6e6;
  color: #212529;
  height: 46px;
  border-right: 6px solid #783184;
}

.main-menu-content > ul > li.selected {
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #e4d6e6;
  color: #212529;
  height: 46px;
  border-right: 6px solid #783184;
}
.main-menu-content > ul > li.focus {
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #e4d6e6;
  color: #212529;
  height: 46px;
  border-right: 6px solid #783184;
}

@media only screen and (max-width: 1279px) {
  .category-list-title {
    display: none;
    -webkit-box-pack: justify; /* Antiguos navegadores WebKit */
    -ms-flex-pack: justify; /* Antiguos IE */
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .category-list-title-mobile {
    display: flex;
    color: #212529;
    font-size: 18px;
    font-style: normal;
    font-family: OpenSans-Bold;
    line-height: 27px; /* 150% */
    -webkit-box-pack: justify; /* Antiguos navegadores WebKit */
    -ms-flex-pack: justify; /* Antiguos IE */
    justify-content: space-between;
  }
  .main-menu-content > ul > li.selected {
    border-radius: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #ffffff00;
    border-right: 0;
  }
  .main-menu-content > ul > li:hover {
    border-radius: 0px;
    background: #ffffff00;
    border-right: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .main-menu-content > ul > li {
    width: 100%;
  }
  .main-menu-content.opened {
    width: 100%;
    height: 100vh;
  }
}
