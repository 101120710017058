.modal-cenco-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-cenco {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.close {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.icon-close-modal {
  outline: none;
  box-shadow: none;
}

.button-close-modal:focus {
  outline: none;
  box-shadow: none;
}

.modal-cenco-body {
  padding: 20px;
}

.modal-cenco-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #dbdee5;
  color: #212529;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding: 20px;
}

.modal-cenco-footer {
  display: flex;
  justify-content: right;
  gap: 10px;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .modal-cenco-container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .modal-cenco {
    width: 100%;
    max-width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 8px 8px 0px 0px;
    z-index: 11;
    padding-bottom: 100px;
  }

  .modal-cenco-header {
    width: 100%;
    border-bottom: 1px solid #dbdee5;
  }

  .modal-cenco-footer {
    width: 100%;
    left: 0;
    justify-content: center;
    border-top: 1px solid #dbdee5;
  }
  .modal-cenco-body {
    overflow-x: auto;
  }
}
