.info-card {
  /* _Card-info */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;

  /* Neutrales/White */
  background: #ffffff;
  /* Neutrales/Bordes */
  border: 1px solid #dbdee5;
  border-radius: 10px;
}

.info-card-exchange-detail {
  align-items: flex-start;
  padding: 16px 12px;
}

.specification-details {
  width: 100%;
}

.linea-divisoria {
  height: 1px;
  background-color: #dbdee5;
  border-radius: 4px;
  margin: 20px 0;
}

.info-card-exchange-conditions {
  align-items: center;
  padding: 16px 12px;
  width: 100%;
}

.product-detail-tab-panel {
  /* Frame 1000003703 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
}

.data-break {
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 768px) {
  .info-card {
    justify-content: center;
  }

  .info-card-exchange-detail {
    align-items: center;

    width: 100%;
  }

  .linea-divisoria {
    margin: 16px 0;
  }

  .info-card-exchange-conditions {
    width: 100%;
  }
}
