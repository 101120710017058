@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #522e76;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #522e76;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Roboto, 'Noto Sans', sans-serif, 'Noto Color Emoji';
  --font-family-monospace: 'Liberation Mono', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, 'Noto Sans', sans-serif, 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #522e76;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: 'Liberation Mono', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: '\2014\A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.fidcl-container,
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.fidcl-container ul li,
.container ul li {
  font-size: 100% !important;
}
.fidcl-container ol li,
.container ol li {
  font-size: 100% !important;
}

@media (min-width: 576px) {
  .fidcl-container,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .fidcl-container,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .fidcl-container,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .fidcl-container,
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem hsla(270, 44%, 92%, 1);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.btn-primary:hover {
  color: #fff;
  background-color: #6b3c9a;
  border-color: #6b3c9a;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem hsla(270, 44%, 92%, 1);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6b3c9a;
  border-color: #6b3c9a;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(270, 44%, 92%, 1);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #522e76;
  border-color: #522e76;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem hsla(270, 44%, 32%, 0.3);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #522e76;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(270, 44%, 32%, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #522e76;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #522e76;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #522e76;
  background-color: #522e76;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #522e76;
  background-color: #522e76;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #522e76;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #522e76;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #522e76;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #522e76;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1.5rem 1.5rem 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #522e76;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #522e76;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #6b3c9a;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #522e76;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, 'Noto Sans', sans-serif, 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, 'Noto Sans', sans-serif, 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #522e76 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #6b3c9a !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #522e76 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: 'Liberation Mono', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #522e76 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map */

.fidcl-text-center {
  text-align: center;
}

.fidcl-h1,
.fidcl-h1 div,
.fidcl-h1 div div,
.fidcl-h1 div div div,
.fidcl-h1 div div div div {
  color: rgb(126, 43, 137);
  font-size: 2.4rem !important;
  font-weight: 400;
  margin: 0;
}

.fidcl-h2 {
  font-size: 3.8rem;
}

.fidcl-h3 {
  font-size: 3rem;
}

.fidcl-h4 {
  font-size: 2.4rem;
}

.fidcl-h5 {
  font-size: 2rem;
}

.fidcl-h6 {
  font-size: 1.6rem;
}

.fidcl-h1-sub {
  font-size: 1.6rem;
  margin: 0;
  font-weight: 400;
}

.fidcl-subtitle {
  margin: 1rem 0;
}

.fidcl-section-title-home {
  color: #522e76;
  text-align: center;
  margin: 3rem 0 1.5rem 0;
  font-size: 3rem;
  font-weight: 200;
}

.fidcl-small {
  font-size: 1.2rem;
}

.fidcl-copyright-footer {
  color: white;
  font-size: 1.2rem;
  margin: 1.5rem 0;
  padding: 0;
}

.fidcl-p {
  color: #666666;
  font-size: 18px;
  font-weight: 400;
}

.fidcl-container {
  width: 90%;
  max-width: 120rem;
  margin: 3rem auto 0 auto;
}

.fidcl-container-home {
  padding: 0 1.5rem;
  width: 123rem;
  margin: 0 auto;
  max-width: 100%;
}

.fidcl-container-home > * {
  padding: 0 !important;
  margin-top: 0 !important;
}

@media (max-width: 1199px) {
  .fidcl-container-home {
    padding: 0 !important;
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .fidcl-p {
    font-size: 16px;
  }
}

.fidcl-container-transac {
  width: 90% !important;
  min-width: auto !important;
  margin-bottom: 15px;
}

.fidcl-divider {
  height: 1.5rem;
  width: 100%;
  clear: both;
}

.formview-container {
  width: 90%;
  max-width: 90rem;
  margin: 0 auto;
}

.fidcl-card-header {
  background: #ffffff;
  border: none;
}

.fidcl-form-data,
.fidcl-display-data {
  font-size: 1.4rem;
  margin-bottom: 1.4rem;
}

.fidcl-display-fullname {
  text-transform: capitalize;
}

.fidcl-select {
  display: block;
  width: 100%;
  border: #87318b solid 0.1rem;
  padding: 0.6rem 1.2rem !important;
  height: 3.6rem;
  margin: 0;
  position: relative;
}

.form-control {
  display: block;
  width: 100%;
  border: #87318b solid 0.1rem;
  padding: 0.6rem 1.2rem !important;
  height: 3.6rem;
  margin: 0;
  position: relative;
}

.form-control * {
  margin: 0;
  padding: 0;
  border: none;
}

/* fix para componentes input base de react native*/

.fidcl-data *[class*='InputBase__TotalContainer'] div:nth-child(2) {
  height: 3.6rem;
  line-height: 1.8;
  font-size: 1.4rem;
}

.fidcl-data *[class*='InputBase__TotalContainer'] div:nth-child(2) div {
  margin: 0;
  padding: 0;
  width: auto;
  height: 100% !important;
  border-color: rgb(206, 212, 218) !important;
}

.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control {
  display: block;
  width: 100%;
  border: none !important;
  padding: 0.6rem 1.2rem !important;
  height: 100% !important;
  margin: 0;
  position: relative;
  border-radius: 0.5rem;
  font-size: 1.4rem !important;
  line-height: 1.4 !important;
  padding-top: 0.8rem !important;
}

.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control:hover,
.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control:active,
.fidcl-data
  *[class*='InputBase__TotalContainer']
  div:nth-child(2)
  div
  .form-control:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: inherit !important;
  line-height: 2 !important;
  transform: none !important;
  height: 100% !important;
  background: #66339900;
}

.fidcl-formaction button {
  vertical-align: top;
}

.fidcl-formaction button[type='submit'] {
  margin-left: 1.5rem;
}

.fidcl-data-select {
  position: relative;
}

.fidcl-data-select::before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #5b2b75;
  position: absolute;
  bottom: 16px;
  right: 10px;
}

.fidcl-bredcrumbs-arrow {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 5px;
  background: #ffffff00;
  line-height: 1 !important;
  color: #ff000000 !important;
}

.fidcl-bredcrumbs-arrow::after {
  position: absolute;
  display: inline-block;
  content: '';
  border-right: 0.1rem solid rgb(176, 176, 176);
  border-bottom: 0.1rem solid rgb(176, 176, 176);
  width: 6px;
  height: 6px;
  left: 0.4rem;
  transform: rotate(-45deg);
}

.fidcl-close {
  display: none !important;
}

.fidcl-healding-main {
  color: hsla(270, 44%, 32%, 1);
  margin: 3rem 0;
  font-size: 3rem;
}

/* Colors */

.fidcl-bg-success-primary {
  background-color: hsl(149, 86%, 36%);
}

.fidcl-bg-success-secondary {
  background-color: #d9f2e5ff;
}

.fidcl-bg-warn-primary {
  background-color: hsl(49, 100%, 39%);
}

.fidcl-bg-warn-secondary {
  background-color: #fff5cc;
}

.fidcl-bg-danger-primary {
  background-color: hsl(357, 91%, 55%);
}

.fidcl-bg-danger-secondary {
  background-color: #fddee0ff;
}

.fidcl-bg-info-primary {
  background-color: #6e2f86ff;
}

.fidcl-bg-info-secondary {
  background-color: #e9e0ed;
}

.fidcl-clr-primary {
  color: #522e76;
}

.fidcl-clr-success-primary {
  color: hsl(149, 86%, 36%);
}

.fidcl-clr-success-secondary {
  color: #d9f2e5ff;
}

.fidcl-clr-warn-primary {
  color: hsl(49, 100%, 39%);
}

.fidcl-clr-warn-secondary {
  color: #fff5cc;
}

.fidcl-clr-danger-primary {
  color: hsl(357, 91%, 55%);
}

.fidcl-clr-danger-secondary {
  color: #fddee0ff;
}

.fidcl-clr-info-primary {
  color: #6e2f86ff;
}

.fidcl-clr-info-secondary {
  color: #e9e0ed;
}

.fidcl-border-success-primary {
  border-color: hsl(149, 86%, 36%);
}

.border-success-secondary {
  border-color: #d9f2e5ff;
}

.fidcl-border-warn-primary {
  border-color: hsl(49, 100%, 39%);
}

.fidcl-border-warn-secondary {
  border-color: #fff5cc;
}

.fidcl-border-danger-primary {
  border-color: hsl(357, 91%, 55%);
}

.fidcl-border-danger-secondary {
  border-color: #fddee0ff;
}

.fidcl-border-info-primary {
  border-color: #6e2f86ff;
}

.fidcl-border-info-secondary {
  border-color: #e9e0ed;
}

/* Pagination */

.fidcl-pagination {
  float: right;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.fidcl-pagination *[data-testid*='Pagination__Button'],
.fidcl-pagination .page-link {
  float: left;
  font-size: 1.6rem;
  position: relative;
  display: block;
  padding: 0.6rem 1rem 0.4rem 1rem;
  line-height: 2rem;
  color: hsla(0, 0%, 80%, 0.95);
  background-color: #fff;
  border: 1px solid hsl(0, 0%, 81%);
  border-right: none;
  text-decoration: none;
  font-weight: 200;
}

.fidcl-pagination *[data-testid*='Pagination__Button']:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

.fidcl-pagination *[data-testid*='Pagination__Button']:last-child {
  border: 1px solid hsla(0, 0%, 80%, 0.95);
  border-radius: 0 0.5rem 0.5rem 0;
}

.fidcl-pagination .page-link svg {
  height: 1.7rem;
  width: auto;
}

.fidcl-pagination .page-link svg path {
  fill: hsla(0, 0%, 80%, 0.95);
}

.fidcl-pagination *[data-testid*='Pagination__Button']:disabled,
.fidcl-pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.fidcl-pagination .page-link.fidcl-pagination-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding: 0.48rem 0.5rem 0.5rem 0.6rem;
  height: 3.2rem;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button']:not(:disabled):not(.disabled):not(.active):hover,
.page-item:not(:disabled):not(.disabled):not(.active):hover * {
  background-color: #f9f9f9;
  color: #522e76;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button--siguente']:not(:disabled):not(.disabled):not(.active)
  div
  div,
.fidcl-pagination
  *[data-testid*='Pagination__Button--siguente']:not(:disabled):not(.disabled):not(.active):hover
  div
  div {
  color: #522e76 !important;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button--anterior']:not(:disabled):not(.disabled):not(.active)
  div
  div,
.fidcl-pagination
  *[data-testid*='Pagination__Button--anterior']:not(:disabled):not(.disabled):not(.active):hover
  div
  div {
  color: #522e76 !important;
}

.fidcl-pagination *[data-testid*='Pagination__Button--siguente']:disabled,
.fidcl-pagination *[data-testid*='Pagination__Button--anterior']:disabled {
  background: #ffffff;
  border-color: #cfcfcf;
  cursor: not-allowed;
}

.fidcl-pagination
  *[data-testid*='Pagination__Button--siguente']:disabled
  div
  div,
.fidcl-pagination
  *[data-testid*='Pagination__Button--anterior']:disabled
  div
  div {
  color: #cfcfcf !important;
}

.page-item:not(:disabled):not(.disabled):not(.active):hover path {
  fill: #522e76;
}

.page-item.active {
  border-right: solid #522e76;
  z-index: 3;
}

.fidcl-header-und {
  font-size: 2.4rem;
  color: #522e76;
  margin-bottom: 1.5rem;
}

.fidcl-header-und img {
  width: 4.5rem;
  margin-top: -0.5rem;
}

.product-container {
  background: #e4e4e4;
}

.col-2_4 {
  width: 20%;
  padding: 0 1.5rem;
}

.fidcl-container-ax-like {
  padding: 0 1.5rem !important;
  width: 123rem !important;
  margin: 0 auto !important;
  max-width: 100% !important;
  margin-bottom: 25px !important;
}

/* RESPONSIVE */

@media (min-width: 768px) {
  .success-container {
    margin-top: 5rem;
  }
  .hide-desktop {
    display: none !important;
  }
}

.fidcl-close-css {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 3rem;
  height: 3rem;
}

.fidcl-close-css:hover {
  opacity: 1;
}

.fidcl-close-css:before,
.fidcl-close-css:after {
  position: absolute;
  left: 15px;
  content: '';
  height: 3rem;
  width: 2px;
  background-color: #522e76;
}

.fidcl-close-css:before {
  transform: rotate(45deg);
}

.fidcl-close-css:after {
  transform: rotate(-45deg);
}

.fidcl-card {
  margin: 1.5rem 0;
  background: white;
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: ease-in box-shadow 0.1s;
  border-radius: 0.3rem;
}

.fidcl-card h6 {
  text-align: center;
  color: #522e76;
  margin: 1.5rem 0;
}

.fidcl-card:hover {
  box-shadow: 0 0 1rem 0rem hsla(0, 0%, 64%, 0.14);
  transition: ease-in box-shadow 0.1s;
}

.fidcl-card.fidcl-card-product:hover button {
  background-color: #522e76;
  color: white;
  transition: ease-in background-color 0.1s;
}

.fidcl-card-product {
  min-height: 27rem;
  position: relative;
}

.fidcl-card-product .fidcl-button {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
}

.fidcl-table-responsive {
  width: 100%;
  overflow: auto;
}

.fidcl-table {
  font-size: 1.4rem;
  width: 100%;
  margin: 3rem 0;
}

.fidcl-table thead {
  background: #e9e0edff;
  color: #522e76;
}

.fidcl-table tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.fidcl-table th,
td {
  padding: 0.75rem 1.5rem;
  border: solid 1px #a0a6ab;
}

.fidcl-table th {
  text-align: left;
}

.fidcl-table td {
  text-align: left;
}

.fidcl-th-action {
  text-align: center;
}

.fidcl-th-number {
  text-align: right;
}

.fidcl-td-action {
  text-align: center;
  padding: 0 !important;
}

.fidcl-td-number {
  text-align: right;
}

/* Table Card */

.fidcl-table-card {
  margin: 0;
}

.fidcl-table-card th,
.fidcl-table-card td {
  border: none;
  border-bottom: solid 0.1rem #e9e0ed;
  padding: 1.5rem;
}

.fidcl-table-card th {
  background: white;
  font-weight: 800;
  color: hsla(0, 0%, 40%, 1);
}

.fidcl-table-card tbody tr:hover {
  background: rgb(249, 249, 249);
}

.fidcl-table-card tr:last-child td {
  border-bottom: none;
}

/* Table Data */

.fidcl-table-data {
  margin: 0;
}

.fidcl-table-data th,
.fidcl-table-data td {
  border: none;
  border-bottom: solid 0.1rem #e9e0ed;
  padding: 1rem !important;
}

.fidcl-table-data th {
  background: white;
  font-weight: 800;
  color: hsla(0, 0%, 40%, 1);
}

.fidcl-table-data tbody tr:hover {
  background: rgb(249, 249, 249);
}

.fidcl-table-data tr:last-child td {
  border-bottom: none;
}

.voucher-container {
  width: 900px;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.fidcl-button {
  padding: 4px;
}

.center-content-row {
  text-align: center;
}

.show-desktop {
  display: block;
}

.show-mobile {
  display: none;
}

@media (max-width: 767px) {
  .success-container {
    margin-top: 10rem;
  }
  .col-2_4 {
    width: 100%;
  }
  .mx-mobile-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .pt-mobile-4 {
    padding-top: 1.5rem;
  }
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .product-container {
    background: #ffffff;
    margin-left: 0;
    margin-right: 0;
  }
  .hide-mobile {
    display: none !important;
  }
  .fidcl-header-und {
    font-size: 2rem;
    color: #522e76;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .fidcl-header-und img {
    width: 4rem;
    margin-top: -0.5rem;
  }
  .fidcl-card-product-horizontal-mobile {
    padding-left: 50%;
    min-height: 20.5rem !important;
  }
  .fidcl-card-product-horizontal-mobile > .img-container {
    position: absolute;
    left: 1.5rem;
    width: 50%;
  }
  .fidcl-card-product-horizontal-mobile h6 {
    padding-left: 3rem;
    text-align: left;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .success-container {
    margin-top: 5rem;
  }
  .hide-desktop {
    display: none !important;
  }
  .hide-mobile {
    display: block !important;
  }
  .fidcl-copyright-footer {
    margin-bottom: 0rem;
  }
  *[class*='RedesSociales'] a > div {
    border: none;
  }
  *[class*='footerContainer'] > div:last-child > div {
    border-bottom: none !important;
  }
}

.fidcl-table-data td.fidcl-td-action-center {
  text-align: center;
}

div.fidcl-button-secondary-outline > div:first-child {
  color: #522e76;
  border: #522e76 solid 0.1rem;
  background: #fff !important;
}

div.fidcl-button-secondary-outline > div:first-child:hover {
  color: #ffffff;
  transition: ease-in all 0.1s;
  background-color: hsl(270, 44%, 32%);
}

div.fidcl-button-secondary-outline > div:first-child > div:first-child {
  color: #522e76 !important;
}

/* FIX DATE SELECT */

.fidcl-col-date-fix {
  margin-bottom: 1.5rem;
}

.fidcl-date-select-container {
  max-width: 35rem;
}

.fidcl-date-select-container > * {
  display: inline-block;
  font-size: 0 !important;
  margin: 0 !important;
}

.fidcl-date-select-container > * > * {
  font-size: 1.4rem;
}

.fidcl-date-select-container > div {
  border-radius: 0;
  width: 48%;
  border: 0;
  height: 4.2rem;
  position: relative;
}

.fidcl-date-select-container > div::before {
  display: block;
  content: '';
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.6rem solid #5b2b75;
  position: absolute;
  bottom: 1.7rem;
  right: 1rem;
}

.fidcl-date-select-container > div > select {
  height: 4.2rem;
  background: #66339900;
}

.fidcl-date-select-container > div:first-child {
  border-radius: 0;
  width: 25%;
}

.fidcl-date-select-container > div:last-child {
  border-radius: 0;
  width: 27%;
}

.fidcl-date-select-container > div:nth-child(2) select {
  border-right: 0;
  border-radius: 0;
  border-left-width: 0.1rem;
}

.fidcl-date-select-container > div select {
  border-radius: 0;
  border-color: rgb(102, 102, 102);
  border-style: solid;
  border-width: 0.1rem;
}

.fidcl-date-select-container > div:first-child select {
  border-width: 0.1rem;
  border-right: 0;
  border-radius: 0.5rem 0 0 0.5rem;
}

.fidcl-date-select-container > div:last-child select {
  border-width: 0.1rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

/* fidcl-phone-fix-container */

.fidcl-phone-fix-container {
  position: relative;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div {
  padding: 0 !important;
  position: relative;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div:nth-child(1)::before {
  content: '+569';
  position: absolute;
  font-size: 1.4rem;
  color: #666666;
  padding-left: 1.5rem;
  padding-top: 1rem;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div input {
  padding: 0.5rem 1.5rem 0.5rem 5.5rem;
}

.fidcl-phone-fix-container > div > div:nth-child(2) > div input:focus {
  outline: none;
}

.fidcl-btn-back {
  display: inline-block !important;
  width: 10rem !important;
  text-transform: capitalize;
}

.btn-table-outline {
  color: #522e76;
  background: #ffffff;
  border-color: #ffffff;
  height: 3.3rem;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  width: auto;
  min-height: auto;
}

.btn-table-icon-left {
  line-height: 2.3;
  height: 3.3rem !important;
  padding-left: 3rem !important;
  position: relative;
  box-sizing: border-box !important;
}

.btn-table-icon-left svg {
  left: 0.5rem;
  position: absolute;
  top: 0.5rem;
}

tr:hover .btn-table-outline {
  color: #522e76;
  border-color: hsla(270, 44%, 32%, 1);
  background-color: rgb(249, 249, 249);
}

tr .btn-table-outline:hover {
  background-color: #522e76;
  border-color: hsla(270, 44%, 32%, 1);
  color: rgb(249, 249, 249);
}

.btn-pure-icon {
  max-width: 4.3rem;
  line-height: 2.5;
  text-align: center;
  padding: 0 !important;
}

.fidcl-btn {
  display: inline-block !important;
  width: auto !important;
  height: 4rem !important;
  line-height: 4rem;
}

.fidcl-home__icon-banner {
  text-align: center;
  display: block;
  width: 100%;
}

.fidcl-home__icon-banner-container > div {
  text-align: center;
}

.fidcl-home__icon-banner-container .anchor-without-href:hover {
  cursor: pointer;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #522e76 !important;
          text-decoration-color: #522e76 !important;
}

.fidcl-home__icon-banner-container > div > a > div,
.fidcl-home__icon-banner-container > div > div {
  max-width: 10rem;
  display: inline-block;
  border: solid 0.3rem rgb(82, 46, 117);
  border-radius: 50%;
  padding: 2rem;
}

.fidcl-home__icon-banner-container > a h3,
.fidcl-home__icon-banner-container > div h3 {
  font-size: 1.6rem;
  color: rgb(82, 46, 117);
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  font-weight: 200 !important;
}

.container-icon-loading {
  display: flex;
}

.container-icon-loading .container-icon-loading-label {
  margin-right: 1rem;
}

/* fidcl-transactions-side */

@media (max-width: 767px) {
  .fidcl-transactions-side {
    width: 100%;
  }
}

.fidcl-transactions-side__box {
  flex-direction: row;
  align-self: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 1 1;
  width: 100%;
}

.fidcl-transactions-side__box-sec-a,
.fidcl-transactions-side__box-sec-b {
  background: #a13e92;
  padding: 1.5rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.fidcl-transactions-side__box-sec-a {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.fidcl-transactions-side__box-sec-b {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.fidcl-transactions-side__box-sec-a .fidcl-h2 {
  font-size: 2rem;
  color: #ffffff;
  text-transform: capitalize;
}

.fidcl-transactions-side__box-sec-a .fidcl-h3 {
  font-size: 1.8rem;
  color: #ffffff;
  text-transform: capitalize;
}

.fidcl-footer > div:nth-child(1) > div:nth-child(2) {
  z-index: 1 !important;
}

.fidcl-card-mobile-title {
  line-height: 2rem;
  font-size: 1.6rem;
}

/* PRODUCT */

.fidcl-product-card {
  max-width: 100%;
}

.fidcl-product-card:hover {
  color: #522e76;
  text-decoration: none;
  background-color: transparent;
}

.fidcl-product-card .btn {
  font-weight: 600;
}

.fidcl-product-card h4 {
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.3;
  font-size: 1.5rem;
  width: 100%;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.fidcl-product-card_badges-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.fidcl-product-card__image-container {
  position: relative;
  text-align: center;
}

.fidcl-product-card__image-container > img {
  max-width: 100%;
  height: 200px !important;
  min-height: 200px !important;
  max-height: 100%;
}

.fidcl-product-card__img-agotado {
  filter: grayscale(100%);
  opacity: 0.5;
}

.fidcl-product-card__tag-agotado {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  padding: 7px 15px;
  position: absolute;
  top: 54px;
  text-align: center;
  width: 100%;
}

.fidcl-product-card__tag-custom-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.fidcl-product-card__body-container {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.fidcl-product-card__puntos-container {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1;
  margin-top: 0.5rem;
}

.fidcl-product-card__puntos-container.strike-through {
  -webkit-text-decoration: line-through #e72470 3px !important;
          text-decoration: line-through #e72470 3px !important;
}

.fidcl-product-card__puntos-container span {
  font-size: 1.4rem;
  font-weight: 200;
  color: hsla(0, 0%, 10%, 1);
}

.fidcl-product-card .btn {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.fidcl-product-card:hover .fidcl-product-card__body-container {
  color: #522e76;
}

.fidcl-product-card:hover .btn-outline-primary {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

.fidcl-product-card__body-container {
  text-align: center;
}

/* card por vista */

.card:hover {
  box-shadow: 0px 0px 6px #ababab82;
}

.slick-slide .card {
  height: 35rem;
  width: 26rem;
  margin: 1.5rem auto;
}

@media (min-width: 0px) {
  div:not(.slick-slide) > div > a > .card {
    height: 38rem;
    width: 26rem;
    margin: 1.5rem;
  }
}

.card > * {
  margin: 1.2rem;
  position: relative;
  height: 100%;
}

/*@media (max-width: 1039px) {*/
/*  *[class*='Catalogo__Title'] > h1,*/
/*  .Catalogo__Title > h1 {*/
/*    font-size: 2.4rem;*/
/*    margin: 1.2rem 0 1.5rem 0;*/
/*    padding: 0;*/
/*  }*/
/*  *[class*='ButtonFilter__Filter'] {*/
/*    right: 0 !important;*/
/*  }*/
/*  div:not(.slick-slide) > div > a > .card {*/
/*    height: 22rem;*/
/*    max-width: 90%;*/
/*    width: 32rem;*/
/*    margin: 1.5rem auto;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card_badges-container {*/
/*    min-height: auto;*/
/*    height: 100%;*/
/*    pointer-events: none;*/
/*    width: 100%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card_badges-container*/
/*    *[class*='EditContainer'] {*/
/*    position: absolute;*/
/*    bottom: 0px;*/
/*    width: 3.7rem;*/
/*    height: 3.6rem;*/
/*  }*/
/*  div:not(.slick-slide) > div > .fidcl-product-card .btn {*/
/*    position: absolute;*/
/*    left: auto;*/
/*    bottom: 0;*/
/*    right: 0;*/
/*    width: 45%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card__body-container {*/
/*    text-align: left;*/
/*    position: absolute;*/
/*    bottom: auto;*/
/*    left: auto;*/
/*    right: 0;*/
/*    width: 45%;*/
/*    top: 0;*/
/*    height: 75%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card__image-container {*/
/*    width: 50%;*/
/*  }*/
/*  .fidcl-product-card__image-container > img {*/
/*    width: 100%;*/
/*  }*/
/*  div:not(.slick-slide)*/
/*    > div*/
/*    > .fidcl-product-card*/
/*    .fidcl-product-card__puntos-container {*/
/*    font-size: 2.2rem;*/
/*    font-weight: 600;*/
/*    line-height: 1;*/
/*    margin-top: 0.5rem;*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*  }*/
/*  .fidcl-catalogo-tienda-und *[class*='ButtonFilter__Filter'] {*/
/*    right: 1.5rem !important;*/
/*  }*/
/*}*/

/* ACCOUNT CARD */

.fidcl-card-account-container-row > div {
  margin-bottom: 3rem;
}

.fidcl-card-account-container-row > div:last-child {
  padding-right: 0;
}

.fidcl-card-account-container-row > div:first-child {
  padding-left: 0;
}

@media (max-width: 767px) {
  .fidcl-card-account-container-row > div {
    padding: 0;
  }
}

/*
.fidcl-card-account-container-row > div:first-child {
  padding-left: 0;
}

.fidcl-card-account-container-row > div:last-child {
  padding-right: 0;
}
*/

.fidcl-card-account-container > * {
  margin: 0;
  background: white;
  padding: 0;
  box-shadow: 0 0 0.75rem 0rem hsla(0, 0%, 0%, 0);
  transition: ease-in box-shadow 0.1s;
  border-radius: 0.3rem;
  text-align: center;
  overflow: hidden;
  border: solid 1px #e6e6e6;
  height: 100%;
}

.fidcl-card-account-container-row {
  width: 100%;
  margin: 0;
}

.fidcl-card-account-container > * > * {
  padding: 1.5rem;
}

.fidcl-card-account-container > * > *:first-child {
  background: hsl(302, 52%, 36%);
  margin: 0;
  color: #ffffff;
  font-size: 1.5rem;
}

.fidcl-card-account-container > * > *:last-child {
  padding: 1.5rem !important;
  margin: 0 !important;
}

.fidcl-card-account-container > * > *:last-child > div {
  font-size: 2.4rem;
  margin: 0;
}

.fidcl-card-account-container .fidcl-card-account-full a {
  font-size: 1.4rem;
  text-decoration: underline !important;
}

.fidcl-card-puntos-full {
  height: 100%;
}

.fidcl-card-puntos-empty {
  background: #e9e0ed;
}

.fidcl-card-account-empty h5 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #5b2b75;
}

.fidcl-card-account-empty a {
  font-size: 1.4rem;
  text-decoration: underline !important;
}

.fidcl-account-subtitle .fidcl-h2 {
  font-weight: 200;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.fidcl-card-link-header {
  position: relative;
}

.fidcl-card-link-header__link {
  font-size: 1.5rem !important;
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;
}

.fidcl-card-account-container > * > .fidcl-card-link-header {
  padding-right: 10.5rem !important;
}

.fidcl-card-link-header__link a {
  color: #522e76;
  border: solid 0.1rem #522e76;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  transition: border-color 0.1s ease-in;
  width: 100%;
}

.fidcl-card-link-header__link a:hover {
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}

/* ESTILOS GLOBALES PARA MARKDOWN .fidcl-markdown {} */

.fidcl-markdown a {
  color: #522e76;
  text-decoration: underline;
}

.fidcl-markdown a:hover {
  color: #522e76;
  text-decoration: none;
}

.fidcl-contact-message {
  margin: 1.5rem 0;
  background: #e5e5e5;
  font-size: 1.5rem;
  padding: 1.5rem;
  text-align: center;
  position: relative;
}

.fidcl-contact-message:before {
  content: '';
  background: #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2000px;
  width: 2000px;
}

.fidcl-contact-message:after {
  content: '';
  background: #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2000px;
  width: 2000px;
}

.fidcl-checkbox-container > div {
  max-width: 100% !important;
}

.fidcl-radio-container {
  font-size: 1.5rem;
  padding: 0 0 1.5rem 0;
}

.fidcl-radio-container [type='radio']:checked,
.fidcl-radio-container [type='radio']:not(:checked) {
  opacity: 0;
  display: none;
}

.fidcl-radio-container [type='radio']:checked + label,
.fidcl-radio-container [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.fidcl-radio-container [type='radio']:checked + label:before,
.fidcl-radio-container [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.fidcl-radio-container [type='radio']:checked + label:after,
.fidcl-radio-container [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #5b2b75;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
}

.fidcl-radio-container:hover [type='radio'] + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #5b2b75;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
}

.fidcl-radio-container [type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.fidcl-radio-container:hover [type='radio'] + label:after {
  opacity: 0.5;
  transform: scale(0.8);
}

.fidcl-radio-container [type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.fidcl-with-loading .loader-container {
  position: relative;
  min-height: 4rem;
}

.fidcl-with-loading .loader-container .spinner {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.swal-button {
  /* Todos los botones de sweetalert */
  padding: 10px 12px;
  align-items: center;
  border-radius: 4px;
  background: #200e3b;
  color: #ffffff;
  border: 1px solid #200e3b;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}
.swal-button:focus {
  background: #200e3b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #f4ebff;
  outline: none;
}

.swal-title {
  color: #212529;
  color: var(--neutrales-gray-1000, #212529);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.swal-title:first-child {
  margin-top: 0 !important;
}
.swal-text {
  font-size: 16px;
  position: relative;
  text-align: center;
  /* line-height: normal; */
  /* vertical-align: bottom; */
  text-align: center;
  /* display: inline-block; */
  /* padding: 0 16px; */
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  max-width: calc(100% - 20px);
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.swal-button:hover:not([disabled]) {
  background: #6b3c9a;
  border-color: #6b3c9a;
}

.actualizar-datos__alert-button--salir {
}

.swal-button--confirm {
}
.swal-button--confirm:hover,
.swal-button--confirm:active {
}

.swal-button--cancel {
  color: #200e3b;
  background: #ffffff;
  border-color: #200e3b;
}
.swal-button--close {
  background: transparent;
  position: absolute;
  color: #333;
  opacity: 1;
  top: 5;
  right: 5;
}
.swal-button--cancel:hover,
.swal-button--cancel:active {
  background: #c4c5c9 !important;
  border-color: #200e3b !important;
}

.swal-button--cancel:focus {
  background: #ffffff;
  border-color: #200e3b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #ffffff;
  outline: none;
}
.swal-button-disabled {
  pointer-events: none;
  cursor: default;
  background: #6c757d;
  border-color: #6c757d;
}
.swal-footer {
  text-align: center;
  padding: 15px 0 25px 0;
}

.swal-modal {
  width: 310px;
}

.swal-content {
  padding: 0 15px;
  margin: 15px 0;
}
.red-bg {
  background: red;
}
.footer-flex > .swal-footer {
  /* Estilos personalizados para el footer */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 0 10px 0;
  /* Agrega otros estilos según tus necesidades */
}
.fixed-bottom > .swal-overlay--show-modal .swal-modal {
  position: fixed;
  bottom: -22px;
  left: 0;
  width: 100%;
  opacity: 1;
  pointer-events: auto;
  box-sizing: border-box;
  animation: showSweetAlert 0.3s;
  will-change: transform;
}
.fixed-bottom > .swal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 0 10px 0;
}
.fixed-bottom {
  position: fixed;
  align-items: center;
  text-align: center;
  bottom: -22px;
  left: 0;
  width: 100%;
}
.full-width-button {
  width: 100%;
}

@keyframes slideDownToUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.swal-custom-animation {
  animation: slideDownToUp 0.3s ease-out;
}

.slick-slider {
  position: relative;
}

.slick-list {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  max-height: 400px;
  overflow: hidden;
}

.slick-track {
  display: flex !important;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;

  flex-direction: row;
  flex-wrap: nowrap;
}

.slick-slide {
  width: 1230px;

  margin: 0 auto;
  text-align: center !important;
}

.slick-slide a {
  overflow: hidden;
}

.slick-dots {
  position: absolute !important;
  bottom: 0px !important;
  box-sizing: border-box;
  padding: 0 10px !important;
}

.slick-dots li {
  height: 10px !important;
}

.slick-arrow {
  width: 50px !important;
  background: transparent !important;
  z-index: 2;
  height: 100% !important;
  top: 0 !important;
  transform: translate(0, 0%) !important;
}

.slick-prev {
  /* left: -50px !important; */
  left: 0 !important;
}

.slick-next {
  /* right: -50px !important; */
  right: 0 !important;
}

.slick-arrow:before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '' !important;
  display: inline-block;
  height: 20px;
  left: 0;
  position: relative;
  top: 0;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 20px;
  border-color: #743387;
}

.slick-prev:before {
  transform: rotate(-135deg);
}

.slick-next:before {
  transform: rotate(45deg);
}

/**/
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: transparent !important;
}

/*Media queries*/

/* Smartphones (not mobile first) ----------- */
/* @media only screen and (max-width : 480px) { */
@media only screen and (max-width: 1200px) {
  .slick-list {
    width: 100%;
    max-height: auto;
    padding: 0;
  }

  .slick-slider {
    width: 100%;
    max-height: auto;
  }

  .slick-slider a img {
    height: 33vw;
  }
}

.ProductoListCarrusel__ContainerSlider-sc-4hgt34-0 .slick-slide {
  width: 340px !important;
}

.ProductoListCarrusel__ContainerSlider-sc-4hgt34-0 .slick-prev {
  left: -50px !important;
}

.ProductoListCarrusel__ContainerSlider-sc-4hgt34-0 .slick-next {
  right: -50px !important;
}

/* Tipografía Agencia */

@font-face {
  font-family: 'Ubuntu', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'OpenSans-Regular', sans-serif;
  overflow-x: hidden;
  overflow-y: visible;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 40px !important;
  /* padding-left: 5px !important; */
  font-family: 'OpenSans-Regular';
  font-size: 14px;
}

select::after {
  content: 'hola';
  width: 20px;
  height: 20px;
  background: red;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

header {
  background: #522e76;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
textarea,
select {
  font-family: 'OpenSans-Regular';
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, monospace;
}

header img {
  max-height: 7rem;
  margin: 0.5rem 0;
}

.form-container {
  max-width: 50rem;
  margin: auto;
}

.btn {
  border-radius: 0.5rem;
  height: 4.2rem;
  display: block;
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 0 1.5rem;
  transition: ease-in border-color 0.1s;
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin: 3rem 0;
  color: #522e75ff;
}

p,
li {
  font-size: 1.4rem;
}

.fidcl-display-test-secure-password {
  padding: 0;
}

.fidcl-display-test-secure-password li {
  font-size: 1.4rem;
  list-style-type: none;
  margin-top: 0.25rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-header .close {
  font-size: 3rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: hsla(270, 44%, 32%, 1);
  font-size: 2rem;
  margin-top: 0;
}

.mt-6 {
  margin-top: 6rem;
}

.fidcl-terms-container.fidcl-modal {
  max-width: 125rem;
  overflow: hidden;
  height: 95%;
}

.fidcl-terms-container.fidcl-modal .modal-content {
  height: 100%;
}

.fidcl-terms-container.fidcl-modal .modal-body {
  height: 100%;
  overflow: auto;
}

.close {
  font-weight: 100;
}

.f-none {
  flex: none !important;
}

.goto-cenco-container {
  padding-right: 3.5rem;
}

.goto-cenco-container > a {
  display: inline-block;
}

.containerButtonsCyT {
  font-size: 1.8rem;
}

.activeTyC {
  color: #3a2855;
  /* background-color: #f4f4f4; */
  border-left: 4px solid;
}

/* 
 * Exchange
 */

.btn-ppc {
  height: 60px !important;
  border-radius: 50px !important;
  opacity: 1 !important;
  font-family: OpenSans-Regular !important;
  font-weight: bold !important;
  font-size: 30px !important;
  width: auto !important;
}

.btn-ppc > p {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 25px !important;
}

.btn-ppc:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn-icon {
  height: 41px;
  width: 41px;
}

.btn-icon-mobile {
  height: 55px;
  width: 55px;
}

.btn-exchange {
  font-size: 20px;
  /*background: #2DC850 0% 0% no-repeat padding-box;*/
  background: #69b727 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 10px;
  opacity: 1;
}

.exchange-header-text {
  text-align: left;
  font: normal normal 900 30px/41px OpenSans-Regular;
  letter-spacing: 0px;
  color: #2c2c2c;
  opacity: 1;
}

.br-10 {
  border-radius: 10px !important;
}

.br-20 {
  border-radius: 20px !important;
}
.br-30 {
  border-radius: 30px !important;
}

.bg-easy {
  background: #e30903;
}

.bg-paris {
  background: #006dff;
}

.bg-jumbo {
  /*background: #2DC850;*/
  background: #69b727;
}

.bg-santa-isabel {
  background: #e2061c;
}

.bg-point {
  background: #532674;
}

.text-point {
  color: #802584;
}
.mb-6 {
  margin-bottom: 4em;
}
.mb-7 {
  margin-bottom: 5em;
}
.mb-8 {
  margin-bottom: 3em;
}
.max-h-40 {
  max-height: 50px;
}

.min-h-40 {
  min-height: 40px;
}

.min-h-30 {
  min-height: 42px;
}

.h4-5 {
  font-size: 1.4rem;
  font-weight: 700;
}

.mb-3px {
  margin-bottom: 3px;
}

.max-h-30 {
  max-height: 42px;
}

.fs-3em {
  font-size: 2em;
}

.icon-arrow-left {
  transform: rotate(-90deg);
  width: 4em;
}

.icon-arrow-right {
  transform: rotate(90deg);
  width: 4em;
}
.position-icon {
  position: relative;
  bottom: 8em;
}

.font-size-18 {
  font-size: 18px;
}

.min-h-150 {
  min-height: 150px;
  max-height: 150px;
}

.content-banner {
  font-size: 3em;
  position: absolute;
  color: white;
  width: 100%;
  height: 90%;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  height: 150px !important;
}

.w-13em {
  width: 13em;
}

.bg-point-product {
  background-color: #802584;
}

.pl-35 {
  padding-left: 35px;
}
.pr-35 {
  padding-right: 35px;
}

.mt-6 {
  margin-top: 4rem !important;
}
.mt-7 {
  margin-top: 5rem !important;
}

.mb-7em {
  margin-bottom: 7em;
}
.without-border:focus {
  box-shadow: none;
}

.generic-product-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mt-1-2 {
  margin-top: 0.15rem;
}

div.strike-through {
  -webkit-text-decoration: line-through #e72470 3px !important;
          text-decoration: line-through #e72470 3px !important;
  text-decoration-line: line-through !important;
  text-decoration-color: #e72470 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 3px !important;
  -webkit-text-decoration-line: line-through !important;
  -webkit-text-decoration-color: #e72470 !important;
  -webkit-text-decoration-style: solid !important;
  -webkit-text-decoration-thickness: 3px !important;
}

.classToIos {
  -webkit-text-decoration: line-through #e72470 3px !important;
          text-decoration: line-through #e72470 3px !important;
  text-decoration-line: line-through !important;
  text-decoration-color: #e72470 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 3px !important;
  -webkit-text-decoration-line: line-through !important;
  -webkit-text-decoration-color: #e72470 !important;
  -webkit-text-decoration-style: solid !important;
  -webkit-text-decoration-thickness: 3px !important;
}

.spinner {
  width: 20px;
  height: 20px;
  animation: contanim 2s linear infinite;
}

.spinner svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(-90deg);
}

.spinner svg:nth-child(1) circle {
  display: none;
  stroke: hsl(270, 44%, 32%);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 2s calc(0.2s * (1)) ease infinite;
  transform-origin: center center;
}

.spinner svg:nth-child(2) circle {
  /*stroke: hsl(270, 44%, 32%);*/
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 6s calc(0.2s * (2)) ease infinite;
  transform-origin: center center;
}

.spinner svg:nth-child(3) circle {
  display: none;
  stroke: hsl(270, 44%, 32%);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 4s calc(0.2s * (3)) ease infinite;
  transform-origin: center center;
}

.spinner svg:nth-child(4) circle {
  /*stroke: hsl(270, 44%, 32%);*/
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 4s calc(0.2s * (4)) ease infinite;
  transform-origin: center center;
}

.loader-container.arc-scale.loader-thin .spinner circle {
  stroke-width: 6px;
}

@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.548324585;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.6449737549;
  }
}

@keyframes contanim {
  100% {
    transform: rotate(360deg);
  }
}

.badge-cenco {
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  background: #b9b9b9;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  mix-blend-mode: multiply;
  border-radius: 16px;
  font-family: 'OpenSans-SemiBold';
}

.badge-ec-tiendas-participantes {
  background: #f2f4f7;
  color: #2a78c0;
}

.badge-ec-tarjeta-cencosud {
  background: #ffeddf;
  color: #f57c03;
}

.badge-ec-puntos-extra {
  background: #ffeefa;
  color: #e72470;
}

.badge-ec-mi-cupon {
  background: #efe5fc;
  color: #190853;
}

.badge-ec-alianzas {
  background: #daebff;
  color: #002046;
}

.badge-ec-canje {
  background: #d2f4e9;
  color: #017753;
}

.badge-ec-SMK {
  background: #ffeefa;
  color: #e72470;
}

.badge-ec-devolucion {
  background: #efefef;
  color: #444e63;
}

.badge-ec-devolución-de-canje {
  background: #efefef;
  color: #444e63;
}

.badge-ec-pago-de-préstamo-de-puntos {
  background: #efefef;
  color: #444e63;
}

.badge-ec-préstamo-de-puntos {
  background: #efefef;
  color: #444e63;
}

.badge-ec-acumulación-por-adicional {
  background: #ffeefa;
  color: #e72470;
}

.badge-additional {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 3px 1px 3px;
  grid-gap: 6px;
  gap: 6px;
  width: 102px;
  height: 25px;
  left: 2194px;
  top: 931px;
  border-radius: 16px;
  font-family: 'OpenSans-SemiBold';
  white-space: nowrap;
}

.badge-with-permissions {
  background: #d3eece;
}

.badge-without-permissions {
  background: #fff6ed;
}

.btn-puntos-cenco {
  padding: 0 12px;
  align-items: center;
  border-radius: 8px;
  background: #200e3b;
  color: #ffffff;
  border: 1px solid #200e3b;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
}

.btn-puntos-cenco-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fid-button-xs {
  height: 32px;
}
.fid-button-sm {
  height: 41px;
}
.fid-button-md {
  height: 44px;
}
.fid-button-lg {
  height: 48px;
}
.fid-button-xl {
  height: 59px;
}

.btn-puntos-cenco:disabled {
  background: #6c757d;
  border-color: #6c757d;
}
.btn-puntos-cenco:hover:not([disabled]) {
  background: #413258;
  border-color: #413258;
}
.btn-readonly {
  cursor: not-allowed !important;
  cursor: none;
  pointer-events: none !important;
  /* opacity: 0.6; Opcional: Ajusta la opacidad según tu diseño */
}
.btn-puntos-cenco:focus {
  background: #200e3b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #f4ebff;
  outline: none;
}

.btn-puntos-cenco-secondary-a {
  color: #200e3b;
  background: #ffffff00;
  border-color: #200e3b;
}
.btn-puntos-cenco-secondary-a:disabled {
  color: #c4c5c9;
  border-color: #ffffff;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.btn-puntos-cenco-secondary-a:hover:not([disabled]) {
  background: #c4c5c9;
  border-color: #200e3b;
}

.btn-puntos-cenco-secondary-a:focus {
  background: #ffffff;
  border-color: #200e3b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #dbdee5;
  outline: none;
}

.btn-puntos-cenco-secondary-b {
  color: #ffffff;
  background: #200e3b;
  border-color: #ffffff;
}
.btn-puntos-cenco-secondary-b:disabled {
  color: #667085;
  border-color: #667085;
  background: #200e3b;
}
.btn-puntos-cenco-secondary-b:hover:not([disabled]) {
  color: #200e3b;
  background: #dbdee5;
  border-color: #200e3b;
}
.btn-puntos-cenco-secondary-b:focus {
  color: #200e3b;
  border-color: #200e3b;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #dbdee5;
  outline: none;
}

.btn-puntos-cenco-tertiary {
  color: #ffffff;
  background: #783184;
  border-color: #783184;
}
.btn-puntos-cenco-tertiary:disabled {
  background: #6c757d;
  border-color: #6c757d;
}
.btn-puntos-cenco-tertiary:hover:not([disabled]) {
  background: #562e5c;
  border-color: #562e5c;
}
.btn-puntos-cenco-tertiary:focus {
  background: #783184;
  border-color: #783184;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #f4ebff;
  outline: none;
}

.btn-santa {
  background: #c92228 !important;
  border-color: #c92228 !important;
}
.btn-jumbo {
  background: #2dc850 !important;
  border-color: #2dc850 !important;
}
.btn-easy {
  background: rgb(227, 9, 23) !important;
  border-color: rgb(227, 9, 23) !important;
}
.btn-paris {
  background: #006dff !important;
  border-color: #006dff !important;
}

.fidcl-alert {
  position: relative;
  padding: 1.5rem;
  border-radius: 0.4rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}

.fidcl-alert-with-icon {
  padding-left: 4.5rem;
  vertical-align: center;
}

.fidcl-alert-with-icon .fidcl-alert--icon {
  position: absolute;
  left: 1.5rem;
  /*top: 2.3rem;*/
}

.fidcl-alert-with-icon .fidcl-alert--icon svg {
  width: 2.2rem;
}

.fidcl-alert > .fidcl-button {
  margin-top: 0.75rem;
}

.fidcl-alert button.close {
}

.fidcl-alert-fix > div > div {
  margin: 0;
}

.fidcl-alert a {
  text-decoration: underline !important;
}

.fidcl-alert-full-w {
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.container-points {
  display: flex;
  justify-content: flex-start;
}
.container-detail-points {
  max-width: 300px;
  margin: 30px 0px 0px -20px;
}

.text-store-cenco-puntos {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #783184;
}
.text-store-cenco-puntos-prime {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #200e3b;
}

.size-puntos-percent {
  font-size: 14px;
}

.badge-percent-off {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0px 10px;
  width: 40px;
  height: 24px;
  background: #ebe0ed;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 2px;
}

.strikethrough-container {
  display: flex;
  justify-content: flex-start;
  padding-right: 30%;
  color: #6f6f6f;
}

.strikethrough {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  font-weight: 600 !important;
}

.strikethrough-detail {
}

.img-puntos {
  margin-left: 4px;
  margin-right: 2px;
  padding-bottom: 8px;
  width: 20px;
}
.img-strikethrough-puntos {
  margin-left: 4px;
  margin-right: 4px;
  padding-bottom: 8px;
  width: 20px;
}
.img-puntos-prime {
  width: 50px;
}

.container-main-image-product {
  height: 0;
  position: relative;
  padding-bottom: 60%;
  display: flex;
}

.image-product-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-product {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 767px) {
  .container-detail-points {
    max-width: 300px;
    margin: 30px 0px 0px -10px;
  }
  .badge-percent-off {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 2px 0px 2px;
    width: 32px;
    height: 24px;
    background: #ebe0ed;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 2px;
  }
  .img-puntos {
    padding-bottom: 8px;
    width: 15px;
  }
  .img-puntos-prime {
    width: 40px;
  }
  .img-strikethrough-puntos {
    width: 15px;
  }
}

.fidcl-badge__text {
  font-size: 1.2rem;
  font-weight: 200;
  color: #fff;
}

.fidcl-badge--new {
  background-color: hsl(270.4, 43.6%, 32%);
}

.fidcl-badge--offert {
  background-color: hsl(325.4, 59.2%, 52.9%);
}

.store-button-v2-container-default {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.store-button-v2-default {
  border-radius: 12px;
  border: 1px solid #dbdee5;
  background: #fff;
  min-width: 180px;
  box-shadow: 0px 2.259px 2.214px 0px rgba(0, 0, 0, 0.01),
    0px 3.726px 5.32px 0px rgba(0, 0, 0, 0.02),
    0px 5.586px 10.017px 0px rgba(0, 0, 0, 0.03),
    0px 8.726px 17.869px 0px rgba(0, 0, 0, 0.03);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 60px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.store-button-v2-default:focus {
  outline: none;
}

.store-button-v2-default:hover {
  background: #eaebec;
}

@media (max-width: 1279px) {
  .store-button-v2-default {
    min-width: 0;
    max-width: 73px;
    border-radius: 20px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    flex: 1 0;
    height: 64px;
    width: 73px;
  }
}

.div-row-container {
  display: flex;
  flex-direction: row;
}
.drc-left {
  justify-content: end;
}
.drc-between {
  justify-content: space-between;
}
.div-column-container {
  display: flex;
  flex-direction: column;
}

.bannerContainer {
  width: 100%;
}

.bannerImage {
  display: block;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.activeZone {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 700px) {
  .bannerImage {
    height: auto;
  }
  .activeZone {
    height: auto;
  }
}

.no-default-style-action-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.no-default-style-action-btn:focus {
  outline: none;
}

.fidcl-product-list-carrusel__title {
  font-weight: 200;
}

.custom-tab-panel {
  width: 100%;
  overflow: hidden;
}

.custom-tab-header {
  display: flex;
}

.custom-tab-header-item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  -webkit-user-select: none;
          user-select: none;
  color: #6f6f6f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  font-family: 'OpenSans-Medium';
}

.custom-tab-header-item.active {
  background-color: #ffffff;
  color: #783184;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-bottom: 2px solid #783184;
  font-family: 'OpenSans-SemiBold';
}

.custom-tab-content {
  padding: 20px 0 0 0;
  background-color: #fff;
}

@media (max-width: 768px) {
  .custom-tab-header {
    flex-direction: row;
  }
  .custom-tab-content {
    margin-top: 24px;
    padding: 0;
  }
  .custom-tab-panel {
    padding: 24px 0 0 0;
  }
}

.info-card {
  /* _Card-info */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;

  /* Neutrales/White */
  background: #ffffff;
  /* Neutrales/Bordes */
  border: 1px solid #dbdee5;
  border-radius: 10px;
}

.info-card-exchange-detail {
  align-items: flex-start;
  padding: 16px 12px;
}

.specification-details {
  width: 100%;
}

.linea-divisoria {
  height: 1px;
  background-color: #dbdee5;
  border-radius: 4px;
  margin: 20px 0;
}

.info-card-exchange-conditions {
  align-items: center;
  padding: 16px 12px;
  width: 100%;
}

.product-detail-tab-panel {
  /* Frame 1000003703 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
}

.data-break {
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

@media (max-width: 768px) {
  .info-card {
    justify-content: center;
  }

  .info-card-exchange-detail {
    align-items: center;

    width: 100%;
  }

  .linea-divisoria {
    margin: 16px 0;
  }

  .info-card-exchange-conditions {
    width: 100%;
  }
}

.product-detail-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product-detail-text {
  font-size: 18px;
  color: #212529;
  line-height: 27px;
  align-content: center;
}

.product-detail-button {
  /* Botón */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  grid-gap: 8px;
  gap: 8px;

  width: 155px;
  height: 44px;

  /* Primarios/Morado oscuro */
  border: 1px solid #200e3b;
  /* Shadow/xs */
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .product-detail-container {
    display: block;
  }
  .product-detail-text {
    font-size: 16px;
    padding-bottom: 16px;
    line-height: 24px;
  }

  .product-detail-button {
    width: 100%;
  }
}

.vista-contenedor-general {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.galeria-imagenes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
}

.badges-container {
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 2;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.badge-edit-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.vista-producto-contenedor {
  display: flex;
  flex: 1 1;
  align-items: stretch;
  padding-left: 30px;
  padding-right: 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.product-detail-flex-row {
  display: flex;
  flex-direction: row;
}

.selectores {
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 15px;
  flex-direction: column;
  width: auto;
  height: 66px;
}

.canje-container {
  flex-direction: row;
  align-items: flex-end;
}

.definiciones {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
}

.titulos-definiciones {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
  font-weight: 400;
  padding: 12px 0;
  font-size: 14px;
}

.imagen-logo {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.especificaciones-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.especificaciones-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  margin-bottom: 30px;
}

.container-direccion {
  margin-bottom: 50px;
}

.title-text-m {
  display: flex;
  font-size: 20px;
}

.condiciones-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
}

.carrusel {
  width: 100%;
}

.galeria-imagenes-width {
  display: flex;
  min-width: 470px;
}

.vista-producto-contenedor-pl {
  display: flex;
  padding-left: 30px;
}

.definiciones-extras {
  display: flex;
  align-items: flex-start;
}

.info-card-exchange {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;

  width: 664px;

  /* Neutrales/Bordes */
  border: 1px solid #dbdee5;
  border-radius: 10px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;

  flex-wrap: wrap;
}

.delivery-free {
  height: 52px;
}

.info-card-title {
  /* Subtitle/Subt-2-Medium */
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  /* Neutrales/Gray 1000 */
  color: #212529;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
}

.delivery-free-title {
  /* Despacho a domicilio */
  width: 527px;
  height: 27px;
}

.delivery-online {
  height: 78px;
}

.delivery-online-title {
  width: 604px;
  height: 54px;
}

.product-detail-badge {
  flex-direction: row;

  width: 65px;
  height: 28px;

  background: #d3eece;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  /* Badges/Green Text */
  color: #1f4116;

  font-size: 16px;
  line-height: 24px;
}

.product-detail-alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;

  width: 344px;
  height: 71px;

  background: #fdf5d0;
  border-radius: 8px;

  flex: none;
  flex-grow: 0;
  z-index: 2;
}

.product-detail-alert-text {
  width: 296px;
  height: 63px;

  /* Body/Body-2-Medium */
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  color: #c7a201;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  /* Texto advertencia */

  width: 280px;
  height: 48px;

  /* Body/Body-1-Semibold */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #bca44a;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .vista-contenedor-general {
    width: 100%;
  }

  .galeria-imagenes-width {
    width: 100%;
    min-width: auto;
  }

  .vista-producto-contenedor-pl {
    display: flex;
    padding-left: 0;
  }

  .definiciones-extras {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .delivery-free {
    width: 100%;
    height: 49px;
    order: 0;
  }

  .info-card-exchange {
    width: 100%;
  }

  .delivery-free {
    height: 49px;
  }

  .delivery-online {
    height: 121px;
  }

  .delivery-free-title {
    width: 208px;
    height: 24px;
  }

  .delivery-online-title {
    width: 279px;
    height: 72px;
  }

  .info-card-title {
    font-size: 16px;
  }
}

.login-form-container-view {
  border: 1px solid rgb(219, 222, 229);
  border-radius: 12px;
  width: 500px;
}

.header-login {
  width: 100%;
}

.first-title-login {
  color: #212529;
  font-family: OpenSans-Bold !important;
  font-size: 24px;
  font-style: normal;
  line-height: 36px;
}

.second-title-login {
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.forget-password {
  text-align: right;
  margin-bottom: 20px;
}

.footer-login {
  display: flex;
  width: 100%;
  flex-direction: row;
  grid-gap: 16px;
  gap: 16px;
}

.footer-login-btn-reg {
  width: 100%;
}

.footer-login-btn-log {
  width: 100%;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .footer-login {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-login-btn-reg {
    margin-left: 0px;
    margin-right: 0px;
  }
  .footer-login-btn-log {
    margin-left: 0px;
    margin-right: 0px;
  }
  .login-form-container-view {
    border: none;
    width: 100%;
  }
  .first-title-login {
    font-size: 20px;
    line-height: 30px;
  }
  .second-title-login {
    font-size: 16px;
    line-height: 24px;
  }
}

.link-puntos-cenco {
  color: #783184;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.link-puntos-cenco-secondary {
  color: #ffffff;
}

.link-puntos-cenco:hover:not([disabled]) {
  color: #783184;
  text-decoration: underline !important;
}
.link-puntos-cenco:focus {
  color: #783184;
}

.link-puntos-cenco-secondary:hover:not([disabled]) {
  color: #eaebec;
  text-decoration: underline !important;
}

.link-puntos-cenco-medium {
  font-size: 18px;
}
.link-white {
  color: #ffffff;
}
.link-purple {
  color: #200e3b;
}

.link-puntos-cenco-disabled {
  color: #c4c5c9;
  pointer-events: none;
}

.icon-left svg {
  margin-right: 3px;
}
.icon-right svg {
  margin-left: 3px;
}

.box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.frame-wrapper {
  border: 0px none;
  height: 25px;
  width: 121px;
}

.frame {
  align-items: flex-start;
  background-color: #eaebec;
  border-radius: 2px;
  background: var(--neutrals-gray-75, #eaebec);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.valid-frame {
  align-items: flex-start;
  background-color: #eaebec;
  border-radius: 2px;
  background: var(--error-green-25, #bff7d6);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invalid-frame {
  align-items: flex-start;
  background-color: #eaebec;
  border-radius: 2px;
  background: var(--error-red-25, #ffe3e5);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  left: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.icon-frame {
  border: 0px none;
  height: 24px;
}

.valid-icon-frame {
  border: 0px none;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 2px;
  background: var(--error-green-25, #bff7d6);
}

.invalid-icon-frame {
  border: 0px none;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 2px;
  background: var(--error-red-25, #ffe3e5);
}

.label {
  color: #344053;
  font-family: 'OpenSans-SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.valid-label {
  color: #09693e;
  font-family: 'OpenSans-SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invalid-label {
  color: #9e0901;
  font-family: 'OpenSans-SemiBold';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.activo {
  background-color: hsla(0, 0%, 85%, 0.73) !important;
}

.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
}

.texto-flotante {
  position: absolute;
  top: 80%;
  left: 20%;
  transform: translate(-10%, -30%);
  color: #9e9e9e;
  font-size: 10px;
  white-space: nowrap;
}

.contenedor-botones-recuperacion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  z-index: 0;
}

.warning-style {
  padding: 15px;
  margin: 10px 0px 10px 0px !important;
  border-radius: 0.375rem;
  background: #fff5cc !important;
  color: #c7a200 !important;
}

.update-data-user__p p,
.update-data-user__p {
  color: hsl(49, 100%, 39%);
}

.edit-field-in-line svg {
  cursor: pointer;
  color: #5b2b75;
  width: 18px;
  margin-right: 8px;
  margin-top: -8px;
}

.edit-field-in-line #email svg {
  margin-top: 6px;
}

.edit-field-in-line input:disabled {
  background-color: white;
}

label {
  font-size: 1.4rem;
}

.form-control {
  position: relative !important;
}

.fi-icon-editable {
  padding-top: 5px;
}

.serv-cliente {
  background: #ffffff;
  min-height: 400px;
  color: #200e3b !important;
}
.sc-img {
  width: 50px;
  height: 50px;
}

.schecudle-info {
  height: 25px;
  font: normal normal normal 18px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.contact-container {
  width: 100%;
  padding-left: 134px;
  padding-right: 140px;
  display: flex;
  justify-content: space-between;
}

.contact-info-text {
  height: 25px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 1.5em OpenSans-Regular;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.contact-info-text-v2 {
  height: 25px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 1.5em OpenSans-Regular;
  letter-spacing: 0px;
  color: #200e3b;
  opacity: 1;
}

.icon-contact-webtres {
  width: 60px;
  height: 60px;
  padding-right: 10px;
}

.schedule-info-v2 {
  height: 25px;
  letter-spacing: 0px;
  color: #200e3b;
  opacity: 1;
  font-size: 15px !important;
}

.serv-cliente .business-contacts .contact-info {
  margin-right: 5em;
}

.serv-cliente-compact .business-contacts .contact-info {
  flex-direction: column;
  margin-right: 3rem;
}

@media (min-width: 1900px) {
  .serv-cliente .business-contacts .contact-info .icon-contact-webtres {
    height: 80px;
    width: 80px;
  }
  .contact-info-text {
    font-size: 2em;
  }
}

.serv-cliente-compact {
  min-height: initial !important;
  width: 100%;
  background-color: transparent !important;
}

.serv-cliente-compact .contact-info-text {
  color: #3a2855 !important;
}

.serv-cliente-compact .contact-info-text-v2 {
  color: #200e3b !important;
}
.business-compact {
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center !important;
}

@media only screen and (max-width: 800px) {
  .serv-cliente-compact > div {
    padding-top: 0rem !important;
  }
  .business-compact > a,
  .business-compact > div {
    width: 50%;
    margin: 0 !important;
  }
  .serv-cliente-compact .business-contacts .contact-info {
    margin-right: 0 !important;
  }
  .serv-cliente-compact > div {
    padding-bottom: 0 !important;
  }
}

.fullpage-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  z-index: 0;
}

.fullpage {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  z-index: 0;
  padding-top: 109px;
}

@media only screen and (max-width: 1279px) {
  .fullpage {
    padding-top: 189px;
  }
}

.fidcl-footer-container {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  background-color: #333333;
  color: #ffffff;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
}

.fidcl-footer-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 150px;
  gap: 150px;
  padding: 20px 130px 20px 130px;
}

.fidcl-footer-column {
  text-align: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 20px;
}

.fidcl-footer-column-title {
  font-family: OpenSans-Bold;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.fidcl-footer-column-section {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 21px;
}

.fidcl-footer-container-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 16px 0;
}
.fidcl-footer-container-social-img {
  width: 24px;
}

.fidcl-footer-container-logo-title {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 24px;
}

.fidcl-footer-container-logo-title-divider {
  width: 120%;
  position: relative;
  margin: 16px 0 16px 0;
  border-bottom: 1px solid #6f6f6f;
}
.fidcl-footer-container-logo-subtitle {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.fidcl-footer-column-link,
.fidcl-footer-column-link:focus
  .fidcl-footer-column-link:hover
  .fidcl-footer-column-link
  > a {
  color: #ffffff;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.fidcl-footer-column-link:hover {
  color: #e1e1e1;
  outline: none;
}

@media only screen and (max-width: 1279px) {
  .fidcl-footer-columns {
    flex-direction: column;
    padding: 0;
    grid-gap: 0;
    gap: 0;
  }
  .fidcl-footer-column {
    grid-gap: 12px;
    gap: 12px;
  }

  .fidcl-footer-column-section-special {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
  }

  .fidcl-footer-container {
    padding-left: 16px;
    padding-right: 16px;
    grid-gap: 16px;
    gap: 16px;
  }

  .fidcl-footer-column-title {
    font-size: 16px;
  }
  .fidcl-footer-container-logo-subtitle {
    text-align: center;
    font-size: 13.5px;
  }
  .fidcl-footer-container-logo {
    padding: 0 0 16px 0;
  }
  .fidcl-footer-container-social-img {
    width: 32px;
  }
}

.card-cenco {
  box-sizing: content-box;
  width: 96.3%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  grid-gap: 1rem;
  gap: 1rem;
  background: #ffffff;
  border: 1px solid #dbdee5;
  border-radius: 8px;
  font-family: 'OpenSans-Medium';
}

.card-cenco-title-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}
.card-cenco-body-container {
}
.card-cenco-footer-container {
  display: flex;
  align-items: baseline;
}

.card-cenco-title {
  margin-left: 5px;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.fidcl-accordion-card {
  width: auto;
  background: rgba(255, 255, 255, 0);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0);
  grid-gap: 20px;
  gap: 20px;
}

.fidcl-accordion-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: #ffffff solid 1px;
}
.fidcl-accordion-card-title {
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.fidcl-accordion-card-toggle-button > button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 4px;
}
.fidcl-accordion-card-action-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.fidcl-accordion-card-description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    margin-top 0.3s ease-out;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
}

.fidcl-accordion-card-description.show {
  margin-top: 16px;
  max-height: 500px;
}

@media only screen and (max-width: 1279px) {
  .fidcl-accordion-card {
    padding: 0px;
  }
  .fidcl-accordion-card-header {
    padding-bottom: 16px;
  }
  .fidcl-accordion-card-description.show {
    margin-top: 0;
  }
}

.tyc-modal-container {
  width: 100%;
  max-width: 692px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: calc(100% - 0.1px);
  position: relative;
  background-color: #ffffff !important;
  border-radius: 12px !important;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}
.tyc-modal-header {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #212529;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.tyc-modal-date {
  padding-right: 15px;
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #667085;
  display: flex;
  align-items: center;
}
.tyc-modal-header-member-name {
  text-transform: capitalize;
  color: #212529;
}
.tyc-modal-safe-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #ffffff;
  overflow-y: hidden;
}
.tyc-modal-scroll {
  overflow-y: scroll;
}
.tyc-modal-scroll-container {
  flex-grow: 1;
  padding: 20px;
}
.tyc-modal-scroll-style {
  flex: 1 1;
  padding: 0px 30px;
}
/*;

.scrollContainer: {
    flexGrow: 1;
    padding: 20;
};
buttonContainer: {
    display: 'flex';
    flexDirection: 'row';
    justifyContent: 'center';
    flexWrap: 'wrap';
    paddingBottom: '10px';
};
buttonCloseContainer: {
    display: 'flex';
    flexDirection: 'column';
    justifyContent: 'flex-end';
    alignItems: 'flex-end';
    marginRight: 10;
    position: 'absolute';
    right: 0;
    zIndex: 10;
};
.text: { flex: 1; color: '#686868'; paddingRight: 15;
    paddingLeft: 15 };
*/

.referInfo {
  height: 29px;
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  margin-bottom: 5px;
}

.date-three-fields-container {
  flex-direction: column;
  display: flex;
}
.date-three-fields {
  flex-direction: row;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

@media (max-width: 991.98px) {
  .referInfo {
    height: 55px;
  }
}

@media (max-width: 400px) {
  .referInfo {
    height: 75px;
  }
}

.styled-select {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACZSURBVHgB7c6xDYMwEAXQf0kspYoiZQE3llAqVsgoTJYRMgIjhIqGhgEoPAASGBBIHOZMjfzL8/e7A2LOnysfaPNOn4/X3drGSh/3ehdeJOBLiv7amFTCXC+Hoh9/24AdkA0s4Zb70AWb1meeg4QLHN+h/dRVVXDMLXbzsjgE+lBAIYSJIEfnqoQFwTUaxg5HJ4keYMTEjOkBRYg4B0o/8BgAAAAASUVORK5CYII=')
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  line-height: normal;
  height: 4.2rem;
  width: 16rem;
  margin: 0px 0px 0px 0px;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgb(150, 150, 150);
  position: relative;
  color: rgb(104, 104, 104);
  z-index: 1;
  padding-left: 15px;
  transition: ease-in border-color 0.1s;
}

.styled-select :hover {
  transition: ease-in border-color 0.1s;
  border-color: hsla(270, 44%, 32%, 1);
}
styled-select :active {
  transition: ease-in box-shadow 0.1s;
  box-shadow: 0 0 0 3px hsla(270, 44%, 92%, 1);
}

.input-invalid {
  border-color: rgb(245, 36, 46) !important;
}
.input-valid {
  border-color: rgb(245, 36, 46);
}

element.style {
  border-color: rgb(245, 36, 46);
}
.bFHvrc:active {
  box-shadow: 0 0 0 3px hsla(357, 91%, 92%, 1);
}
.bFHvrc:active {
  transition: ease-in box-shadow 0.1s;
  box-shadow: 0 0 0 3px hsla(270, 44%, 92%, 1);
  box-shadow: 0 0 0 3px hsla(357, 91%, 92%, 1);
}
.bFHvrc:hover {
  transition: ease-in border-color 0.1s;
  border-color: hsla(270, 44%, 32%, 1);
}

.numero-serie-btn {
  background: none;
  border: none;
}

.numero-serie-btn:active {
  outline: 0;
}

.input-serie {
  text-transform: uppercase;
}

.input-serie::placeholder {
  text-transform: none !important;
}

.guia {
  object-fit: contain;
  width: 70%;
}

.noInset {
  inset: 'unset';
}

.fidcl-close {
  display: none !important;
}
.fidcl-healding-main {
  color: #522e76;
  margin: 3rem 0;
  font-size: 3rem;
}
.fidcl-bg-success-primary {
  background-color: #0dab59;
}
.fidcl-bg-success-secondary {
  background-color: #d9f2e5;
}
.fidcl-bg-warn-primary {
  background-color: #c7a200;
}
.fidcl-bg-warn-secondary {
  background-color: #fff5cc;
}
.fidcl-bg-danger-primary {
  background-color: #f5242e;
}
.fidcl-bg-danger-secondary {
  background-color: #fddee0;
}
.fidcl-bg-info-primary {
  background-color: #6e2f86;
}
.fidcl-bg-info-secondary {
  background-color: #e9e0ed;
}
.fidcl-clr-primary {
  color: #522e76;
}
.fidcl-clr-success-primary {
  color: #0dab59;
}
.fidcl-clr-success-secondary {
  color: #d9f2e5;
}
.fidcl-clr-warn-primary {
  color: #c7a200;
}
.fidcl-clr-warn-secondary {
  color: #fff5cc;
}
.fidcl-clr-danger-primary {
  color: #f5242e;
}
.fidcl-clr-danger-secondary {
  color: #fddee0;
}
.fidcl-clr-info-primary {
  color: #6e2f86;
}
.fidcl-clr-info-secondary {
  color: #e9e0ed;
}
.fidcl-border-success-primary {
  border-color: #0dab59;
}
.border-success-secondary {
  border-color: #d9f2e5;
}
.fidcl-border-warn-primary {
  border-color: #c7a200;
}
.fidcl-border-warn-secondary {
  border-color: #fff5cc;
}
.fidcl-border-danger-primary {
  border-color: #f5242e;
}
.fidcl-border-danger-secondary {
  border-color: #fddee0;
}
.fidcl-border-info-primary {
  border-color: #6e2f86;
}
.fidcl-border-info-secondary {
  border-color: #e9e0ed;
}
.fidcl-pagination {
  float: right;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.fidcl-pagination .page-link {
  font-size: 1.6rem;
  position: relative;
  display: block;
  padding: 0.6rem 1rem 0.4rem;
  line-height: 2rem;
  color: hsla(0, 0%, 80%, 0.95);
  background-color: #fff;
  border: 1px solid hsla(0, 0%, 80%, 0.95);
  text-decoration: none;
  font-weight: 200;
}
.fidcl-pagination .page-link svg {
  height: 1.7rem;
  width: auto;
}
.fidcl-pagination .page-link svg path {
  fill: hsla(0, 0%, 80%, 0.95);
}
.fidcl-pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}
.fidcl-pagination .page-link.fidcl-pagination-icon {
  padding: 0.48rem 0.5rem 0.5rem 0.6rem;
  height: 3.2rem;
}
.page-item:not(:disabled):not(.disabled):not(.active):hover * {
  background-color: #f9f9f9;
  color: #522e76;
}
.page-item:not(:disabled):not(.disabled):not(.active):hover path {
  fill: #522e76;
}
.page-item.active {
  border-right: solid #522e76;
  z-index: 3;
}
.fidcl-header-und {
  font-size: 2.4rem;
  color: #522e76;
  margin-bottom: 1.5rem;
}
.fidcl-header-und img {
  width: 4.5rem;
  margin-top: -0.5rem;
}
.product-container {
  background: #e4e4e4;
}
.col-2_4 {
  width: 20%;
  padding: 0 1.5rem;
}
.fidcl-table-responsive {
  width: 100%;
  overflow: auto;
}
.fidcl-table {
  font-size: 1.4rem;
  width: 100%;
  margin: 3rem 0;
}
.fidcl-table thead {
  background: #e9e0ed;
  color: #522e76;
}

.fidcl-th-number {
  text-align: right;
}
.fidcl-td-action {
  text-align: center;
  padding: 0 !important;
}
.fidcl-td-number {
  text-align: right;
}
.fidcl-table-card {
  margin: 0;
}
.fidcl-table-card td,
.fidcl-table-card th {
  border: none;
  border-bottom: 0.1rem solid #e9e0ed;
  padding: 1.5rem;
}
.fidcl-table-card th {
  background: #fff;
  font-weight: 800;
  color: #666;
}
.fidcl-table-card tbody tr:hover {
  background: #f9f9f9;
}
.fidcl-table-card tr:last-child td {
  border-bottom: none;
}
.fidcl-table-data {
  margin: 0;
}
.fidcl-table-data td,
.fidcl-table-data th {
  border: none;
  border-bottom: 0.1rem solid #e9e0ed;
  padding: 1.5rem;
}
.fidcl-table-data th {
  background: #fff;
  font-weight: 800;
  color: #666;
}
.fidcl-table-data tbody tr:hover {
  background: #f9f9f9;
}
.fidcl-table-data tr:last-child td {
  border-bottom: none;
}
@media (min-width: 768px) {
  .success-container {
    margin-top: 5rem;
  }
  .hide-desktop {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .success-container {
    margin-top: 10rem;
  }
  .col-2_4 {
    width: 100%;
  }
  .mx-mobile-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .pt-mobile-4 {
    padding-top: 1.5rem;
  }
  .show-desktop {
    display: none;
  }
  .product-container {
    background: #fff;
    margin-left: 0;
    margin-right: 0;
  }
  .hide-mobile {
    display: none !important;
  }
  .fidcl-header-und {
    font-size: 2rem;
    color: #522e76;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .fidcl-header-und img {
    width: 4rem;
    margin-top: -0.5rem;
  }
}
.fidcl-close-css {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 3rem;
  height: 3rem;
}
.fidcl-close-css:hover {
  opacity: 1;
}
.fidcl-close-css:after,
.fidcl-close-css:before {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 3rem;
  width: 2px;
  background-color: #522e76;
}
.fidcl-close-css:before {
  transform: rotate(45deg);
}
.fidcl-close-css:after {
  transform: rotate(-45deg);
}
.form-container {
  max-width: 50rem;
  margin: auto;
}
.btn {
  border-radius: 0.5rem;
  height: 4.2rem;
  display: block;
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 0 1.5rem;
  transition: border-color 0.1s ease-in;
  width: 100%;
}
.fidcl-display-test-secure-password {
  padding: 0;
}
.fidcl-display-test-secure-password li {
  font-size: 1.4rem;
  list-style-type: none;
  margin-top: 0.25rem;
}
.modal-body {
  padding: 1.5rem;
}
.modal-header .close {
  font-size: 3rem;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #522e76;
  font-size: 2rem;
  margin-top: 0;
}
.mt-6 {
  margin-top: 6rem;
}
.fidcl-terms-container.fidcl-modal {
  max-width: 125rem;
  overflow: hidden;
  height: 95%;
}
.fidcl-terms-container.fidcl-modal .modal-content {
  height: 100%;
}
.fidcl-terms-container.fidcl-modal .modal-body {
  height: 100%;
  overflow: auto;
}
.close {
  font-weight: 100;
}
.f-none {
  flex: none !important;
}
.goto-cenco-container {
  padding-right: 3.5rem;
}
.goto-cenco-container > a {
  display: inline-block;
}
.fidcl-input {
  text-align: left;
  min-height: 10rem;
}
.fidcl-input-container {
  position: relative;
}
.fidcl-input label {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.fidcl-input input {
  border-radius: 0.5rem;
  height: 4.2rem;
  display: block;
  box-sizing: border-box;
  font-size: 1.4rem;
  border: 0.1rem solid #9e9e9e;
  padding: 0 1.5rem;
  transition: border-color 0.1s ease-in;
  width: 100%;
}
.fidcl-input .fidcl-input-before-icon-container input {
  padding-left: 4.8rem;
}
.fidcl-input-before-icon {
  position: absolute;
  top: 0.8rem;
  left: 1.5rem;
  pointer-events: none;
}
.fidcl-input .fidcl-input-after-icon-container input {
  padding-right: 4.8rem;
}
.fidcl-input-after-icon {
  position: absolute;
  top: 0.8rem;
  right: 1.5rem;
  pointer-events: none;
}
.fidcl-input input:hover {
  border-color: #522e76;
  transition: border-color 0.1s ease-in;
}
.fidcl-input input:focus {
  outline: none;
  border-color: #522e76;
  box-shadow: 0 0 0 2px #ebe2f4;
}
.fidcl-input input:active,
.fidcl-input input:focus {
  transition: box-shadow 0.1s ease-in;
}
.fidcl-input input:active {
  box-shadow: 0 0 0 3px #ebe2f4;
}
.fidcl-input input:disabled {
  background-color: #f3f3f3;
}
.fidcl-input input:disabled:hover {
  border-color: #525252;
}
.fidcl-input input:disabled:focus {
  box-shadow: 0 0 0 2px #ebebeb;
}
.fidcl-input input:disabled:active {
  box-shadow: 0 0 0 3px #ebebeb;
}
.fidcl-input-before-icon-actionable {
  cursor: pointer;
  color: #522e76;
}
.fidcl-input-before-icon-actionable-password {
  pointer-events: all;
}
.fidcl-input.fidcl-error input {
  border-color: #f5242e;
}
.fidcl-input.fidcl-success input {
  border-color: #00943e;
}
.fidcl-input.fidcl-error input:disabled:hover {
  border-color: #525252;
}
.fidcl-input.fidcl-error input:focus {
  box-shadow: 0 0 0 2px #fdd8da;
}
.fidcl-input.fidcl-error input:active {
  box-shadow: 0 0 0 3px #fdd8da;
}
.fidcl-input.fidcl-success input:focus {
  box-shadow: 0 0 0 2px #c7ffde;
}
.fidcl-input.fidcl-success input:active {
  box-shadow: 0 0 0 3px #c7ffde;
}
.fidcl-error-text {
  color: #f5222d;
  font-size: 1.4rem;
  margin-top: 0.25rem;
}
.fidcl-sucess-text {
  color: #00923e;
  font-size: 1.4rem;
  margin-top: 0;
}
.fidcl-input-tel .fidcl-input-before-icon {
  font-size: 1.4rem;
  top: 1.06rem;
  color: #9e9e9e;
}
.fidcl-input-tel input {
  padding-left: 5.5rem !important;
}
.fidcl-button {
  flex: auto;
}
.fidcl-button button {
  border: 0.1rem solid #522e76;
  background-color: #522e76;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  min-height: 4rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  display: inline-block;
  width: auto;
}
.fidcl-button .fidcl-button-block {
  width: 100%;
}
.fidcl-button button:hover {
  background-color: #6b3c9a;
}
.fidcl-button button:focus,
.fidcl-button button:hover {
  transition: all 0.1s ease-in;
  border-color: #522e76;
}
.fidcl-button button:focus {
  background-color: #381f51;
  outline: none;
  box-shadow: 0 0 0 2px rgba(82, 46, 118, 0.4);
}
.fidcl-button button:active {
  background-color: #381f51;
  transition: all 0.1s ease-in;
  box-shadow: 0 0 0 3px rgba(82, 46, 118, 0.4);
}
.fidcl-button button:not(:disabled):not(.disabled).active:focus,
.fidcl-button button:not(:disabled):not(.disabled):active:focus {
  background-color: #381f51;
  transition: all 0.1s ease-in;
  outline: none;
  border-color: #522e76;
  box-shadow: 0 0 0 2px rgba(82, 46, 118, 0.4);
}
.fidcl-button button:not(:disabled):not(.disabled).active,
.fidcl-button button:not(:disabled):not(.disabled):active {
  background-color: #381f51;
  transition: all 0.1s ease-in;
  box-shadow: 0 0 0 3px rgba(82, 46, 118, 0.4);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
button.fidcl-button-secondary-outline {
  color: #522e76;
  background: #fff;
}
button.fidcl-button-secondary-outline:hover {
  color: #fff;
  border-color: #522e76;
  transition: all 0.1s ease-in;
  background-color: #522e76;
}
button.fidcl-button-secondary-outline:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(82, 46, 118, 0.4);
}
button.fidcl-button-secondary-outline:active,
button.fidcl-button-secondary-outline:focus {
  color: #fff;
  transition: all 0.1s ease-in;
}
button.fidcl-button-table-outline {
  color: #522e76;
  background: #fff;
  border-color: #fff;
  height: 3.3rem;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  width: auto;
  min-height: auto;
}
table tr td button.fidcl-button-table-outline {
  transition: all 0.1s ease-in;
}
table tr:hover td button.fidcl-button-table-outline {
  color: #fff;
  border-color: #522e76;
  background-color: #522e76;
}
button.fidcl-button-table-outline:focus {
  outline: none;
  border-color: #522e76;
  box-shadow: 0 0 0 2px #ebe2f4;
}
button.fidcl-button-table-outline:active,
button.fidcl-button-table-outline:focus {
  transition: all 0.1s ease-in;
  color: #522e76 !important;
  background-color: #fff !important;
}
button.fidcl-button-table-outline:active {
  box-shadow: 0 0 0 3px #ebe2f4;
}
.fidcl-button button.fidcl-button-warn {
  transition: all 0.1s ease-in;
  border-color: #c7a200;
  background-color: #c7a200;
}
.fidcl-button button.fidcl-button-warn:hover {
  transition: all 0.1s ease-in;
  border-color: #e0b700;
  background-color: #e0b700;
}
.fidcl-button button.fidcl-button-warn:active,
.fidcl-button button.fidcl-button-warn:focus,
.fidcl-button button.fidcl-button-warn:not(:disabled):not(.disabled):active {
  transition: all 0.1s ease-in;
  border-color: #bd9c0a;
  background-color: #bd9c0a;
  box-shadow: 0 0 0 3px rgba(199, 162, 0, 0.4);
}
.fidcl-button button.fidcl-button-danger {
  transition: all 0.1s ease-in;
  border-color: #f5242e;
  background-color: #f5242e;
}
.fidcl-button button.fidcl-button-danger:hover {
  transition: all 0.1s ease-in;
  border-color: #f7555d;
  background-color: #f7555d;
}
.fidcl-button button.fidcl-button-danger:active,
.fidcl-button button.fidcl-button-danger:focus,
.fidcl-button button.fidcl-button-danger:not(:disabled):not(.disabled):active {
  transition: all 0.1s ease-in;
  border-color: #ff1a25;
  background-color: #ff1a25;
  box-shadow: 0 0 0 3px rgba(245, 36, 46, 0.4);
}
.fidcl-button button.fidcl-button-success {
  border-color: #0dab59;
  background-color: #0dab59;
}
.fidcl-button button.fidcl-button-success:hover {
  border-color: #04b459;
  background-color: #04b459;
}
.fidcl-button button.fidcl-button-success:active,
.fidcl-button button.fidcl-button-success:focus,
.fidcl-button button.fidcl-button-success:not(:disabled):not(.disabled):active {
  transition: all 0.1s ease-in;
  border-color: #16a259;
  background-color: #16a259;
  box-shadow: 0 0 0 3px rgba(13, 171, 89, 0.4);
}
.fidcl-button.fidcl-button-sm button {
  min-height: 3.2rem;
  height: 3.2rem;
}
.fidcl-button-und > button {
  min-width: 12rem;
  margin: 0 0 0 3rem !important;
}
.fidcl-button-und {
  position: relative;
}
.fidcl-button-und .logo {
  position: absolute;
  content: '';
  right: 10rem;
  bottom: 0;
  height: 4.2rem;
  width: 4.2rem;
  margin: auto;
  border-radius: 50%;
}
.fidcl-button-paris button {
  color: #009de0;
  border-color: #009de0;
}
.fidcl-button-paris.active button,
.fidcl-button-paris button:hover {
  background: #009de0;
  border-color: #009de0;
  color: #fff;
}
.fidcl-button-jumbo button {
  color: #00923e;
  border-color: #00923e;
}
.fidcl-button-jumbo button:hover {
  border-color: #00923e;
  background: #00923e;
}
.fidcl-button-santa-isabel button {
  color: #e20612;
  border-color: #e20612;
}
.fidcl-button-santa-isabel button:hover {
  border-color: #e20612;
  background: #e20612;
}
.fidcl-button-easy button {
  color: #e30917;
  border-color: #e30917;
}
.fidcl-button-easy button:hover {
  border-color: #e30917;
  background: #e30917;
}
.fidcl-button-johnson button {
  color: #ef7e00;
  border-color: #ef7e00;
}
.fidcl-button-johnson button:hover {
  border-color: #ef7e00;
  background: #ef7e00;
}
.colores-colores {
  color: #0dab59;
  color: #0caa59;
  color: #04b459;
  color: #03b358;
  color: rgba(13, 171, 89, 0.4);
  color: rgba(12, 170, 89, 0.4);
  color: #c7a200;
  color: #c6a200;
  color: #e0b700;
  color: rgba(199, 162, 0, 0.4);
  color: rgba(198, 162, 0, 0.4);
  color: #f5242e;
  color: #f4232e;
  color: #f7555d;
  color: #f6555d;
  color: rgba(245, 36, 46, 0.4);
  color: rgba(244, 35, 46, 0.4);
  color: #6c2e84;
  color: #6b2e84;
  color: #6b3c9a;
  color: #6b3b9a;
  color: rgba(108, 46, 132, 0.4);
  color: rgba(107, 46, 132, 0.4);
  color: #e8dfec;
  color: #e9e0ed;
}
.fidcl-button-default > i {
  position: absolute;
  right: 3rem;
}
.fidcl-checkbox {
  padding: 1.5rem 0 3rem 3.5rem;
  position: relative;
}
.fidcl-checkbox > span {
  vertical-align: top;
  display: inline-block;
  padding-top: 0.6rem;
  font-size: 1.4rem;
}
.fidcl-checkbox-item {
  display: inline-block;
  position: absolute;
  width: 2.2rem;
  border: 0.1rem solid #9e9e9e;
  height: 2.2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.2s ease-in;
  top: 1.9rem;
  left: 0;
}
.fidcl-checkbox-item-uncheck svg {
  opacity: 0;
  margin: -0.1rem 0 0 0.1rem;
  color: #fff;
  transition: all 0.2s ease-in;
  width: 1.9rem;
}
.fidcl-checkbox-item-uncheck:hover {
  border: 0.1rem solid #522e76;
}
.fidcl-checkbox-item-check {
  border: 0.1rem solid #522e76;
  background: #522e76;
}
.fidcl-checkbox-item-check svg {
  opacity: 1;
  margin: -0.1rem 0 0 0.1rem;
  color: #fff;
  transition: all 0.2s ease-in;
  width: 1.9rem;
}
.fidcl-checkbox-item input[type='checkbox'] {
  opacity: 0;
  width: 100%;
  height: 100%;
  zoom: 5;
  position: absolute;
  top: -1rem;
  left: -1rem;
  transform: scale(2);
}
.focus .fidcl-checkbox-item {
  border: 0.1rem solid #522e76;
  box-shadow: 0 0 0 2px #ebe2f4;
}
.fidcl-terms h2 {
  font-size: 2rem;
}
.fidcl-terms h3 {
  font-size: 1.6rem;
}
.fidcl-error-message {
  background-color: #fddddf;
  border-radius: 0.5rem;
  color: #f5242e;
  font-size: 1.4rem;
  position: relative;
  padding: 1.5rem 1.5rem 1.5rem 5rem;
}
.fidcl-error-message svg {
  position: absolute;
  left: 1.5rem;
  height: 2.8rem;
  width: 2.8rem;
  top: 1rem;
}
.fidcl-card {
  margin: 1.5rem 0;
  background: #fff;
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: box-shadow 0.1s ease-in;
  border-radius: 0.3rem;
}
.fidcl-card h6 {
  text-align: center;
  color: #522e76;
  margin: 1.5rem 0;
}
.fidcl-card:hover {
  box-shadow: 0 0 1rem 0rem hsla(0, 0%, 64%, 0.14);
  transition: box-shadow 0.1s ease-in;
}
.fidcl-card.fidcl-card-product:hover button {
  background-color: #522e76;
  color: #fff;
  transition: background-color 0.1s ease-in;
}
.fidcl-card-product {
  min-height: 27rem;
  position: relative;
}
.fidcl-card-product .fidcl-button {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
}
@media (max-width: 767px) {
  .fidcl-card-product-horizontal-mobile {
    padding-left: 50%;
    min-height: 20.5rem !important;
  }
  .fidcl-card-product-horizontal-mobile > .img-container {
    position: absolute;
    left: 1.5rem;
    width: 50%;
  }
  .fidcl-card-product-horizontal-mobile h6 {
    padding-left: 3rem;
    text-align: left;
    margin: 0;
  }
}
.fidcl-alert {
  position: relative;
  padding: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}
.fidcl-alert-with-icon {
  padding-left: 4.5rem;
}
.fidcl-alert-with-icon .fidcl-alert--icon {
  position: absolute;
  left: 1.5rem;
  top: 1.3rem;
}
.fidcl-alert-with-icon .fidcl-alert--icon svg {
  width: 2.2rem;
}
.fidcl-alert > .fidcl-button {
  margin-top: 0.75rem;
}
.spinner {
  width: 20px;
  height: 20px;
  animation: contanim 2s linear infinite;
}
.spinner svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(-90deg);
}
.spinner svg:first-child circle {
  display: none;
  stroke: #522e76;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 2s ease 0.2s infinite;
}
.spinner svg:first-child circle,
.spinner svg:nth-child(2) circle {
  transform-origin: center center;
}
.spinner svg:nth-child(2) circle {
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 6s ease 0.4s infinite;
}
.spinner svg:nth-child(3) circle {
  display: none;
  stroke: #522e76;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 4s ease 0.6s infinite;
}
.spinner svg:nth-child(3) circle,
.spinner svg:nth-child(4) circle {
  transform-origin: center center;
}
.spinner svg:nth-child(4) circle {
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 4s ease 0.8s infinite;
}
.loader-container.arc-scale.loader-thin .spinner circle {
  stroke-width: 6px;
}
@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.548324585;
  }
  to {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.6449737549;
  }
}
@keyframes contanim {
  to {
    transform: rotate(1turn);
  }
}
.date-range-sud > div {
  width: 50%;
}
.date-sud {
  position: relative;
}
.date-sub--open {
  display: block;
}
.date-sub--close {
  display: none;
}
.date-sud__calendar {
  position: absolute;
  top: 5.2rem;
  z-index: 100;
  background-color: #fff;
  border: 0.1rem solid #e6e6e6;
  height: 24.5rem;
  width: 30rem;
  left: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem;
  overflow: hidden;
}
.date-sud__calendar button:focus {
  outline: none;
  background-color: #d9d9d9 !important;
}
.date-sud .show {
  display: block;
}
.date-sud .hidden {
  display: none;
}
.date-sud .date-sud__day {
  width: 14.28%;
  height: 3.13rem;
  font-size: 1.4rem;
  text-align: center;
  line-height: 2.2;
  float: left;
  position: relative;
  background: none;
  border: none;
}
.date-sud .date-sud__day:not(:disabled) {
  cursor: pointer;
}
.date-sud .date-sud__day.other-month {
  background-color: #f0f0f0;
}
.date-sud .date-sud__day:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 0.1rem solid #e6e6e6;
  border-top: 0.1rem solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.date-sud .date-sud__day:nth-child(7n + 1):after {
  border-left: none;
}
.date-sud .date-sud__day:not(:disabled):hover {
  background-color: #f2f2f2;
}
.date-sud .fidcl-input-container input:focus + div {
  display: block;
}
.data-sud__header {
  width: 100%;
  display: block;
  height: 3.5rem;
}
.data-sud__header > * {
  display: block;
  float: left;
  height: 3.5rem;
  font-size: 1.4rem;
  line-height: 2.7;
  border: none;
  background-color: initial;
}
.data-sud__header > button:first-child,
.data-sud__header > button:last-child {
  width: 14.285714285714286%;
  text-align: center;
  line-height: 2.4;
}
.data-sud__header > div:nth-child(2) {
  width: 71.4285714286%;
}
.date-sud__title-month,
.date-sud__title-year {
  float: left;
  background-color: initial;
  height: 100%;
  border: none;
  font-size: 1.4rem;
}
.date-sud__title-month {
  width: auto;
  padding: 0 0.5rem;
  text-transform: capitalize;
}
.date-sud__calendar .date-sud__months button {
  float: left;
  width: 33.3333333%;
  height: 5.24rem;
  position: relative;
  text-align: center;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 3.6;
  background: none;
  border: none;
}
.date-sud__calendar .date-sud__months button:not(:disabled):hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.date-sud__calendar .date-sud__months button:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 0.1rem solid #e6e6e6;
  border-top: 0.1rem solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.date-sud .date-sud__months button:nth-child(3n + 1):after {
  border-left: none;
}
.date-sud__calendar .date-sud__years button {
  float: left;
  width: 33.3333333%;
  height: 4.21rem;
  position: relative;
  text-align: center;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 3;
  background: none;
  border: none;
}
.date-sud__calendar .date-sud__years button:not(:disabled):hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.date-sud__calendar .date-sud__years button:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 0.1rem solid #e6e6e6;
  border-top: 0.1rem solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.date-sud .date-sud__years button:nth-child(3n + 1):after {
  border-left: none;
}
.date-sud__name-days {
  float: left;
  width: 14.285714285714286%;
  padding: 0.5rem;
  text-align: center;
  box-sizing: border-box;
  height: 2.2rem;
}
.date-sud__calendar-second {
  left: 30.16rem;
}
.date-sud__header-info {
  right: 4.32rem;
  top: 1.16rem;
  position: absolute;
  font-size: 1.1rem;
  display: inline-block;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
  color: #1e90ff;
}
.date-sud .date-sud__day.date-sud-in-range-persist {
  background-color: #dadce7;
}
.date-sud .date-sud__day.date-sud-in-range,
.date-sud .date-sud__day.date-sud-in-range:hover {
  background-color: #e0e2eb;
}
.fidcl-loading-modal-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.fidcl-loading-modal {
  background: #fff;
  padding: 3rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.1s ease-in;
  border-radius: 0.3rem;
  width: 50rem;
  text-align: center;
  display: block;
  height: auto;
  margin: auto;
  position: absolute;
  top: calc(30% - 4rem);
  left: 0;
  right: 0;
  max-width: 90%;
}
.fidcl-loading-modal .header {
  background: #fff;
  font-size: 2.4rem;
  color: #522e76;
  position: relative;
  line-height: 1;
  margin-bottom: 1.5rem;
}
.fidcl-loading-modal .content {
  text-align: justify;
}
.fidcl-loading-modal footer {
  display: inline-block;
}
/*# sourceMappingURL=main.cb7d6148.chunk.css.map */

.modal-cenco-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-cenco {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.close {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.icon-close-modal {
  outline: none;
  box-shadow: none;
}

.button-close-modal:focus {
  outline: none;
  box-shadow: none;
}

.modal-cenco-body {
  padding: 20px;
}

.modal-cenco-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #dbdee5;
  color: #212529;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding: 20px;
}

.modal-cenco-footer {
  display: flex;
  justify-content: right;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .modal-cenco-container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .modal-cenco {
    width: 100%;
    max-width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 8px 8px 0px 0px;
    z-index: 11;
    padding-bottom: 100px;
  }

  .modal-cenco-header {
    width: 100%;
    border-bottom: 1px solid #dbdee5;
  }

  .modal-cenco-footer {
    width: 100%;
    left: 0;
    justify-content: center;
    border-top: 1px solid #dbdee5;
  }
  .modal-cenco-body {
    overflow-x: auto;
  }
}

.cenco-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.cenco-blur {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-family: OpenSans-Medium;
  line-height: 27px;
  z-index: 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.first-title-modal-completeness {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: #212529;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
}

.second-title-modal-completeness {
  max-width: 600px;
  font-family: 'OpenSans-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.footer-modal-completeness {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.modal-completeness-footer {
  display: flex;
  justify-content: right;
  grid-gap: 10px;
  gap: 10px;
  border-top: 1px solid #dbdee5;
}

.modal-completeness-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.modal-completeness-column {
  padding: 12px;
}

.modal-completeness-title {
  width: 286px;
  height: 24px;
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-completeness-text {
  width: 286px;
  height: 15px;
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-decoration: none !important;
}

.text-one-line {
  height: 15px;
}

.text-two-line {
  height: 30px;
}

.text-multiple-lines {
  height: 45px;
}

.modal-completeness-break-words {
  /* break words */
  word-wrap: break-word !important;
  white-space: unset !important;
  word-break: break-word;
}

.empty-field-required {
  width: 95px;
  height: 15px;
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9e0901;
  padding-left: 4px;
}

.empty-field-optional {
  width: 54px;
  height: 16px;
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6f6f6f;
  padding-left: 4px;
}

.modal-completeness-body {
  overflow-y: auto;
  float: left;
  position: relative;
}

@media only screen and (max-height: 667px) {
  .modal-completeness-body {
    max-height: 170px;
  }
}

@media (min-height: 668px) and (max-height: 720px) {
  .modal-completeness-body {
    max-height: 251px;
  }
}

@media (min-height: 721px) and (max-height: 740px) {
  .modal-completeness-body {
    max-height: 270px;
  }
}

@media (min-height: 741px) and (max-height: 950px) {
  .modal-completeness-body {
    max-height: 330px;
  }
}

@media only screen and (max-width: 950px) {
  .modal-completeness-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-completeness-column {
    padding: 6px;
  }

  .footer-modal-completeness {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
  }

  .text-one-line {
    height: 21px;
  }

  .text-two-line {
    height: 42px;
  }

  .text-multiple-lines {
    height: 63px;
  }
}

.toast-container {
  /* background: #ffe3e5; */
  grid-gap: 5px;
  gap: 5px;
  height: 44px;
  padding: 8px;
  border-radius: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.whatsapp-pop-up {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 5;
}

.pre-header-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.pre-header-container.top {
  position: fixed;
}
.pre-header-container.hidden {
  visibility: hidden;
  opacity: 0;
}
.pre-header-container.up {
  position: fixed;
  z-index: 2;
}

/* Add vendor prefixes for position and transition */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pre-header-container {
  }
}

.pre-header {
  background: #333333 !important;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.pre-header-link {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
}

.pre-header-box-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
}

.pre-header-link:hover {
  background: #212121;
}

.pre-header-link a {
  color: white;
}

@media only screen and (max-width: 768px) {
  .pre-header-container {
    background: #333333 !important;
  }

  .pre-header {
    width: 100% !important;
    justify-content: center;
    grid-auto-columns: 1fr;
  }

  .no-mobile {
    width: 0px;
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .pre-header-container {
    padding-left: 33%;
    padding-right: 40px;
    background: #333333 !important;
  }

  /* Add vendor prefixes for padding */
  @supports (-ms-ime-align: auto) {
    .pre-header-container {
      -ms-padding-left: 33%;
      -ms-padding-right: 40px;
    }
  }

  .pre-header-link-ca {
    width: 130px !important;
  }
}

/* Navbar styles */
.cenco-navbar { /* Antiguos navegadores WebKit */ /* Antiguos IE */
  display: flex;
  position: fixed;
  top: 29px;
  padding: 12px 32px;
  width: 100%; /* Antiguos navegadores WebKit */ /* Antiguos navegadores WebKit */ /* Antiguos IE */
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px; /* Antiguos navegadores WebKit */ /* Antiguos IE */
  justify-content: space-between; /* Antiguos navegadores WebKit */ /* Antiguos IE */
  align-items: center;
  background-color: #200e3b;
  color: #ffffff;
  height: 80px;
  font-size: 16px;
  font-style: normal;
  font-family: OpenSans-SemiBold;
  line-height: 24px;
  z-index: 2;
}

.cenco-navbar.top {
  position: fixed;
}
.cenco-navbar.down {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  opacity: 1; /* Antiguos navegadores WebKit */
  transition: visibility 0.5s, opacity 0.5s linear;
}
.cenco-navbar.up {
  position: fixed;
  top: 29px;
  right: 0;
  left: 0; /* Antiguos navegadores WebKit */
  transition-property: position, top; /* Antiguos navegadores WebKit */
  transition-duration: 0.5s;
}

.points-navbar-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.cenco-nav-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.display-flex-desk-mobile {
  display: none;
}

.cenco-nav-sub-container {
  display: flex;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  gap: 20px;
  white-space: nowrap;
}

.logo-menu-mb {
  display: flex;
}

.cenco-nav-sub-container-responsive-m {
  display: none;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.cenco-nav-button-container {
  min-width: 260px;
}

/* Logo styles */
.logo {
  font-weight: bold;
}
.logo-img {
  width: 60px;
}

.search-navbar {
  margin-top: 5px;
  height: 44px;
  min-width: 300px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* Other button styles */
button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

/* Search box styles */
input {
  padding: 5px;
}

/* Dialog styles */
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 999;
}

.dialog button {
  background-color: #333;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
.burger-menu {
  display: none;
}
.nav-option-container {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
}
display-block-desk {
  display: block !important;
}
.display-flex-desk {
  display: flex !important;
}

.account-sigin-mobile-menu {
  display: none;
}

.btn-header-navbar:focus {
  outline: none !important;
}

.display-none-desk {
  display: none !important;
}
@media only screen and (max-width: 1279px) {
  .display-flex-desk-mobile {
    display: flex;
  }
  .display-none-mobile {
    display: none !important;
  }
  .display-flex-mobile {
    display: flex !important;
  }
  .display-block-mobile {
    display: block !important;
  }
  .cenco-nav-container {
    justify-content: normal;
  }

  .cenco-navbar {
    height: 160px;
    padding: 15px 24px;
    z-index: 6;
  }

  .cenco-nav-button-container {
    min-width: 0;
  }

  .cenco.cenco-nav-container {
    flex-direction: column; /* Antiguos navegadores WebKit */ /* Antiguos IE */
    align-items: flex-start;
  }

  .nav-option-container {
    display: none;
  }

  .burger-menu {
    display: block;
    margin-left: -5px;
  }

  .logo-img {
    width: 50px;
    height: 50px;
  }
  .cenco-nav-sub-container {
    grid-gap: 0px;
    gap: 0px;
    justify-content: space-between;
  }
  .cenco-nav-sub-container-responsive-m {
    display: flex;
  }
  .account-sigin-mobile-menu {
    display: flex;
    z-index: 1;
  }
  .points-navbar-mobile {
    display: flex;
  }
  .logo-img {
    width: 50px;
    height: 50px;
  }
}

.submenu-container {
  position: absolute;
  top: 0;
  left: 400px;
  width: 0;
  overflow-x: hidden;
  z-index: 5;
  height: 100vh;
  font-size: 18px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  overscroll-behavior: contain;
}

.submenu-container.opened {
  left: 400px;
  width: 400px;
  padding: 30px;
}

.submenu-header {
  font-size: 20px;
  font-style: normal;
  font-family: OpenSans-SemiBold;
  line-height: 150%;
  margin-bottom: 12px;
}

.button-back-sublist-menu {
  display: none;
}

.submenu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submenu-list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.submenu-list li button {
  color: #212529;
  font-size: 18px;
  font-family: OpenSans-Medium;
  font-style: normal;
  line-height: 27px;
}

.submenu-list-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.divider-subcategory {
  width: 100% !important;
}

@media only screen and (max-width: 1279px) {
  .submenu-container {
    left: 0px;
    background-color: #f3f2f7;
    box-shadow: none;
    border-top: 26px solid #783184;
  }
  .submenu-container.opened {
    width: 100%;
    left: 0;
    z-index: 20;
    padding: 30px 5px 30px 5px;
  }

  .submenu-list ul li {
    font-size: 18px;
    font-family: OpenSans-Medium;
    font-style: normal;
    line-height: 27px;
  }
  .button-back-sublist-menu {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  .view-all-button-subcategory {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  .submenu-header {
    padding-left: 2px;
    padding-right: 6px;
  }
}

.divider-container {
  width: 50%;
  height: 4px;
  border-radius: 8px;
  flex: none;
  flex-grow: 0;
  margin-bottom: 10px;
  display: flex;
}

.divider-progress {
  height: 2px;
  left: 0%;
  right: 85.97%;
  top: 2px;
  background: #200e3b;
  border-radius: 4px;
  display: flex;
}

.divider-background {
  width: 100%;
  height: 2px;
  left: 0px;
  right: 0px;
  top: 2px;
  background: #eaebec;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .divider-container {
    width: 90%;
  }
}

.category-list-title {
  display: flex;
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-family: OpenSans-SemiBold;
  line-height: 27px; /* 150% */ /* Antiguos navegadores WebKit */ /* Antiguos IE */
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eaebec;
}
.category-list-title-mobile {
  display: none; /* Antiguos navegadores WebKit */ /* Antiguos IE */
  justify-content: space-between;
  margin-bottom: 30px;
}

.no-menu-overlay { /* Antiguos navegadores WebKit */
  flex: 3 1;
}

.main-menu-content {
  position: relative;
  top: 0;
  left: 0;
  padding: 36px;
  width: 400px;
  background: #f3f2f7;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  overflow-x: hidden;
  transform: translateX(-400px);
  transition: transform 0.5s;
  z-index: 3;
}

.main-menu-content.opened {
  transform: translateX(0px);
  width: 400px;
  padding: 36px;
  height: calc(100vh - 80px);
}

.main-menu-content > ul { /* Antiguos navegadores WebKit */ /* Antiguos IE */
  display: flex; /* Antiguos navegadores WebKit */ /* Antiguos navegadores WebKit */ /* Antiguos IE */
  flex-direction: column;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.main-menu-content > ul > li {
  width: 319px;
  height: 46px;
  color: #212529;
  font-size: 18px;
  font-family: OpenSans-Medium;
  font-style: normal;
  line-height: 27px;
  list-style-type: none;
  display: flex; /* Antiguos navegadores WebKit */ /* Antiguos IE */
  justify-content: space-between;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}

.main-menu-content > ul > li:hover {
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #e4d6e6;
  color: #212529;
  height: 46px;
  border-right: 6px solid #783184;
}

.main-menu-content > ul > li.selected {
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #e4d6e6;
  color: #212529;
  height: 46px;
  border-right: 6px solid #783184;
}
.main-menu-content > ul > li.focus {
  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #e4d6e6;
  color: #212529;
  height: 46px;
  border-right: 6px solid #783184;
}

@media only screen and (max-width: 1279px) {
  .category-list-title {
    display: none; /* Antiguos navegadores WebKit */ /* Antiguos IE */
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .category-list-title-mobile {
    display: flex;
    color: #212529;
    font-size: 18px;
    font-style: normal;
    font-family: OpenSans-Bold;
    line-height: 27px; /* 150% */ /* Antiguos navegadores WebKit */ /* Antiguos IE */
    justify-content: space-between;
  }
  .main-menu-content > ul > li.selected {
    border-radius: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #ffffff00;
    border-right: 0;
  }
  .main-menu-content > ul > li:hover {
    border-radius: 0px;
    background: #ffffff00;
    border-right: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .main-menu-content > ul > li {
    width: 100%;
  }
  .main-menu-content.opened {
    width: 100%;
    height: 100vh;
  }
}

.point-container {
  margin-left: 3px;
  margin-right: 3px;
  align-items: center;
  display: flex;
}
.point-account-status {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.point-account-status-plus {
  color: #783184;
}
.point-account-status-minus {
  color: #444e63;
}

.container-main-menu {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-family: OpenSans-Medium;
  line-height: 27px;
  z-index: 2;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.container-main-menu.opened {
  width: 100vw;
}
.container-main-menu.closed {
  width: 0;
}

.cmm-down-fixed {
  position: relative;
  opacity: 1; /* Antiguos navegadores WebKit */
  transition: visibility 0.3s, opacity 0.3s linear;
}

.cmm-up-fixed {
  margin-top: 109px;
  position: fixed;
}

.main-menu-overlay {
  overflow-y: hidden;
  overflow-x: hidden;
  overscroll-behavior: contain;
  height: calc(100vh - 109px);
}

.main-menu-overlay-fixed {
  height: calc(100vh - 80px);
}

.main-menu-overlay.opened {
  width: 400px;
}

.input-search-menu-mobile {
  margin-top: 5px;
  font-size: 16px;
  height: 44px;
  min-width: 300px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  color: #191919;
  padding: 15px;
  width: 100%;
}

.input-search-menu-mobile:focus {
  transition: ease-in box-shadow 0.1s;
  outline: #783184;
  box-shadow: 0 0 0 2px #783184;
}

.input-wrapper-menu-mobile {
  position: relative;
}

.input-icon-menu-mobile {
  color: #191919;
  position: absolute;
  width: 22px;
  height: 22px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px #dbdee5;
  border-left-style: solid;
  padding-left: 5px;
}

.list-dropdown-menu-mobile {
  list-style-type: none;
  padding: 0;
}

.list-dropdown-menu-mobile li {
  color: #212529;
  font-family: OpenSans-Medium;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.div-resp-mobile {
  display: none;
}

@media only screen and (max-width: 1350px) {
  .input-search-menu-mobile {
    margin-right: 10px;
    min-width: 260px;
  }
  .input-icon-menu-mobile {
    padding-left: 5px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1279px) {
  .div-resp-mobile {
    display: block;
  }
  .div-help-center {
    margin-bottom: 100px;
  }
  .title-main-menu {
    color: #212529;
    font-family: OpenSans-Bold;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
  }
  .buttons-main-menu {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .main-menu-overlay {
    border-top: 26px solid #783184;
    height: 100vh;
  }

  .main-menu-overlay.opened {
    width: 100%;
  }

  .cmm-down-fixed {
    margin-top: 0px;
    position: fixed;
  }

  .cmm-up-fixed {
    margin-top: 0;
  }
  .input-icon-menu-mobile {
    width: 22px;
    height: 22px;
    top: 45%;
  }
  .input-wrapper-menu-mobile {
    width: 100%;
  }
  .list-dropdown-menu-mobile {
    padding-inline-start: 0;
  }

  .list-dropdown-menu-mobile li {
    margin-bottom: 20px;
  }
  .title-mobile {
    color: #212529;
    font-family: OpenSans-Bold;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
  }
  .title-points-mobile {
    color: #783184;
    font-size: 18px;
    font-style: normal;
    font-family: OpenSans-Bold;
    line-height: 27px;
  }
}

.custom-style-modal-login {
  width: 490px;
}

.custom-style-header-modal-login {
  padding-bottom: 10px;
}

.custom-style-body-modal-login {
  padding: 15px 20px 20px;
  border-bottom: 1px solid #dbdee5;
}

.forget-password-modal {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 30px;
}

.footer-modal-login {
  align-self: end;
}

@media only screen and (max-width: 768px) {
  .footer-modal-login {
    width: 100%;
  }
}

.profile-container {
  z-index: 99999;
  background-color: #200e3b;
  height: 105px;
  border-radius: 0 0 10px 10px;
  width: 190px;
  color: white;
  transition: width 0.3s;
  position: fixed;
  right: 2em;
  margin-top: -12px;
}
profile-container.up {
  position: fixed;
  top: 29px;
}
profile-container.down {
  position: fixed;
  top: 0;
}
profile-container.top {
  position: fixed;
  top: 29px;
}

.profile-tras {
  width: 277px;
  right: 19px;
}

.profile-container-responsive {
  padding-bottom: 16px;
  color: white;
  height: auto;
  width: 255px;
  right: 0px;
  border-radius: 0px 0px 10px 10px;
}

.menu-profile-responsive {
  padding-bottom: 16px;
  background-color: #200e3b;
  color: white;
  position: absolute;
  height: auto;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  top: 85px;
  left: 0px;
}

.menu-profile {
  padding-bottom: 16px;
  z-index: 99999;
  background-color: #200e3b;
  color: white;
  position: fixed;
  height: auto;
  width: 277px;
  right: 19px;
  /* pacity: 0.95;
    filter:  alpha(opacity=50);*/
  border-radius: 0px 0px 10px 10px;
}

.ul-menu-responsive {
  padding-left: 4px;
  margin: 2em;
}

.menu-profile-responsive .li-menu {
  list-style: none;
  font-size: larger;
  font-weight: 100;
}
@media (max-width: 320px) {
  .menu-profile-responsive .li-menu {
    max-height: 45px;
  }
}

.ul-menu {
  margin-top: 8em;
  padding-left: 31px;
}

.menu-profile .li-menu {
  list-style: none;
  font-size: larger;
  font-weight: 100;
}

.up-menu {
  cursor: pointer;
  transform: rotate(-180deg);
  animation: up 0.3s;
}

.down-menu {
  cursor: pointer;
  transform: rotate(0deg);
  animation: down 0.3s;
}

.opacity-profile {
  opacity: 0.1;
  filter: alpha(opacity=50);
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

.fade-out {
  opacity: 0.95;
  animation-name: fadeinout;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
}

@keyframes down {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes up {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0.95;
  }
  100% {
    opacity: 0;
  }
}

.point-menu {
  font-size: 20px;
  letter-spacing: 2px;
}

.c-white {
  color: 'white';
}
.mc-1 {
  margin: 1px;
}
.mc-2 {
  margin: 2px;
}
.mc-5 {
  margin: 5px;
}
.w-14em-nav-item {
  width: 12em;
}

.menu-dropdown {
  margin-top: 10px;
  position: fixed;
  min-width: 236px;
  background: #f9fafb;
  line-height: 24px;
  border-radius: 6px;
  box-shadow: 0 1px 0 -1px rgba(15, 24, 40, 0.05),
    0 0 0 -1px rgba(16, 24, 40, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.25),
    0 1px 1px 0 rgba(0, 0, 0, 0.25);
  grid-gap: 16px;
  gap: 16px;
  z-index: 1;
  padding-left: 16px;
}

.menu-dropdown-icon {
  width: 16px;
  height: 16px;
  grid-gap: 0;
  gap: 0;
}

.menu-dropdown-arrow::before {
  content: '';
  position: absolute;
  top: -9px;
  left: 90%;
  transform: translateX(-50%);
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: #f9fafb transparent;
}

.menu-dropdown ul { /* Antiguos navegadores WebKit */ /* Antiguos IE */
  display: flex;
  text-align: start; /* Antiguos navegadores WebKit */ /* Antiguos navegadores WebKit */ /* Antiguos IE */
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
  margin-block-start: 12px;
  margin-block-end: 12px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 12px;
  list-style-type: none;
}

.menu-dropdown-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-inline-start: 12px;
  grid-gap: 5px;
  gap: 5px;
  justify-items: center;
}

.menu-dropdown li button {
  text-align: start;
  list-style-type: none;
  font-family: OpenSans-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

@media (hover: hover) {
  .menu-dropdown li button:hover {
    color: #783184;
    font-family: OpenSans-Bold;
  }
}

.logout-border {
  border-top: 1px solid #dbdee5;
  padding-top: 14px;
}

.menu-dropdown-my-account-spc {
  margin-top: 60px !important;
}

.empty-failed-exchange-container {
  /* Empty state_Mis Canjes */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  grid-gap: 24px;
  gap: 24px;

  position: -webkit-sticky;

  position: sticky;
  width: 768px;
  height: 295px;
  left: calc(50% - 768px / 2);
  top: 150px;

  /* Badges/Gray */
  background: #f2f4f7;
  border-radius: 8px;
}

.empty-failed-exchange-title {
  width: 471px;
  height: 27px;

  /* Subtitle/Subt-2-Bold */
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  /* Neutrales/Gray 1000 */
  color: #212529;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.empty-failed-exchange-sub-title {
  height: 24px;

  /* Body/Body-1-Medium */
  font-family: 'OpenSans-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: center;

  /* Neutrales/Gray 1000 */
  color: #212529;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.see-failed-exchange-button {
  /* Botón */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  grid-gap: 8px;
  gap: 8px;

  width: 161px;
  height: 44px;

  /* Primarios/Morado oscuro */
  background: #200e3b;
  /* Primarios/Morado oscuro */
  border: 1px solid #200e3b;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.div-margin-top {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .empty-failed-exchange-container {
    /* Empty state_Mis Canjes */
    width: 343px;
    height: 322px;
    left: calc(50% - 343px / 2);
  }

  .see-failed-exchange-button {
    /* Botón */
    /* Auto layout */
    display: flex;
    padding: 10px 16px;

    width: 100%;
    height: 41px;

    /* Inside auto layout */
    order: 2;
    align-self: stretch;
  }

  .empty-failed-exchange-title {
    /* Tenemos problemas con el canje que deseas realizar */

    width: 303px;
    height: 54px;
    text-align: center;

    /* Inside auto layout */
    align-self: stretch;
  }

  .empty-failed-exchange-sub-title {
    /* Por favor intenta de nuevo más tarde. */
    width: 303px;
    height: 24px;
  }
}

.loading-confirmation-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-confirmation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  grid-gap: 12px;
  gap: 12px;
}

.loading-confirmation-title {
  width: 396px;

  font-family: 'OpenSans-Bold';
  font-style: normal;

  font-size: 32px;
  line-height: 48px;
}

.loading-confirmation-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.loading-confirmation-primary-button {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  grid-gap: 8px;
  gap: 8px;

  width: 146px;
  height: 44px;

  /* Primarios/Morado oscuro */
  border: 1px solid #200e3b;
  /* Shadow/xs */
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 4px;
}

.loading-confirmation-secondary-button {
  width: 146px;
  height: 44px;
}

.div-margin-top {
  margin-top: 30px;
}

@media (max-width: 764px) {
  .loading-confirmation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 16px;
    grid-gap: 12px;
    gap: 12px;
  }

  .loading-confirmation-title {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
  }

  .div-margin-top {
    margin-top: 0;
  }

  .loading-confirmation-buttons {
    width: 100%;
  }

  .loading-confirmation-primary-button {
    width: 100%;
  }

  .loading-confirmation-secondary-button {
    width: 100%;
  }
}

