.product-detail-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product-detail-text {
  font-size: 18px;
  color: #212529;
  line-height: 27px;
  align-content: center;
}

.product-detail-button {
  /* Botón */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  width: 155px;
  height: 44px;

  /* Primarios/Morado oscuro */
  border: 1px solid #200e3b;
  /* Shadow/xs */
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 4px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .product-detail-container {
    display: block;
  }
  .product-detail-text {
    font-size: 16px;
    padding-bottom: 16px;
    line-height: 24px;
  }

  .product-detail-button {
    width: 100%;
  }
}
