.vista-contenedor-general {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.galeria-imagenes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
}

.badges-container {
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 2;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.badge-edit-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.vista-producto-contenedor {
  display: flex;
  flex: 1;
  align-items: stretch;
  padding-left: 30px;
  padding-right: 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.product-detail-flex-row {
  display: flex;
  flex-direction: row;
}

.selectores {
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 15px;
  flex-direction: column;
  width: auto;
  height: 66px;
}

.canje-container {
  flex-direction: row;
  align-items: flex-end;
}

.definiciones {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0;
  width: 100%;
}

.titulos-definiciones {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
  font-weight: 400;
  padding: 12px 0;
  font-size: 14px;
}

.imagen-logo {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.especificaciones-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.especificaciones-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  margin-bottom: 30px;
}

.container-direccion {
  margin-bottom: 50px;
}

.title-text-m {
  display: flex;
  font-size: 20px;
}

.condiciones-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
}

.carrusel {
  width: 100%;
}

.galeria-imagenes-width {
  display: flex;
  min-width: 470px;
}

.vista-producto-contenedor-pl {
  display: flex;
  padding-left: 30px;
}

.definiciones-extras {
  display: flex;
  align-items: flex-start;
}

.info-card-exchange {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;

  width: 664px;

  /* Neutrales/Bordes */
  border: 1px solid #dbdee5;
  border-radius: 10px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;

  flex-wrap: wrap;
}

.delivery-free {
  height: 52px;
}

.info-card-title {
  /* Subtitle/Subt-2-Medium */
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */

  /* Neutrales/Gray 1000 */
  color: #212529;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
}

.delivery-free-title {
  /* Despacho a domicilio */
  width: 527px;
  height: 27px;
}

.delivery-online {
  height: 78px;
}

.delivery-online-title {
  width: 604px;
  height: 54px;
}

.product-detail-badge {
  flex-direction: row;

  width: 65px;
  height: 28px;

  background: #d3eece;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  /* Badges/Green Text */
  color: #1f4116;

  font-size: 16px;
  line-height: 24px;
}

.product-detail-alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;

  width: 344px;
  height: 71px;

  background: #fdf5d0;
  border-radius: 8px;

  flex: none;
  flex-grow: 0;
  z-index: 2;
}

.product-detail-alert-text {
  width: 296px;
  height: 63px;

  /* Body/Body-2-Medium */
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  color: #c7a201;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  /* Texto advertencia */

  width: 280px;
  height: 48px;

  /* Body/Body-1-Semibold */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #bca44a;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

@media (max-width: 768px) {
  .vista-contenedor-general {
    width: 100%;
  }

  .galeria-imagenes-width {
    width: 100%;
    min-width: auto;
  }

  .vista-producto-contenedor-pl {
    display: flex;
    padding-left: 0;
  }

  .definiciones-extras {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .delivery-free {
    width: 100%;
    height: 49px;
    order: 0;
  }

  .info-card-exchange {
    width: 100%;
  }

  .delivery-free {
    height: 49px;
  }

  .delivery-online {
    height: 121px;
  }

  .delivery-free-title {
    width: 208px;
    height: 24px;
  }

  .delivery-online-title {
    width: 279px;
    height: 72px;
  }

  .info-card-title {
    font-size: 16px;
  }
}
